import {Badge} from "react-bootstrap";
import './chatListItem.scss';
import {format} from "date-fns";

const ChatListItem = ({ chat, isActive, onClickItem }) => {
	const badgeBuilder = (userType) => {
		switch (userType) {
			case 'courier':
				return <Badge bg="primary">Чат с курьером</Badge>;
			case 'merchant':
				return <Badge bg="success">Чат с продавцом</Badge>;
			case 'user':
				return <Badge bg="dark">Чат с клиентами</Badge>;
			default:
				return <Badge bg="secondary">Чат</Badge>;
		}
	};

	return (
		<div
			onClick={() => onClickItem()}
			className={`chatsListItem ${isActive ? 'chatsListItemActive' : ''}`}
		>
			<div className="chatsListItemTitle">
				<p><b>{`${chat?.user?.surname || ''} ${chat?.user?.name || ''}`}</b></p>
				{badgeBuilder(chat?.user?.role)}
			</div>
			<div className="chatsListItemContent">
				{chat?.chatMessages.length > 0 ? (
					<>
						<span>{`${chat?.chatMessages[0]?.content.slice(0, 28)}...`}</span>
						<span>{format(new Date(chat?.chatMessages[0]?.createdAt || new Date()), 'dd.MM.yyyy - HH:mm')}</span>
					</>
				) : (
					<><span>Сообщений пока нет</span></>
				)}
			</div>
		</div>
	)
};

export default ChatListItem;