import {useEffect, useState} from "react";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, InputGroup, Table, Badge} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import './couponsPage.scss';
import {getAllMarketplaces} from "../../../api/marketplaceAPI";
import {getAllCoupons} from "../../../api/couponeAPI";
import DatePicker from "react-datepicker";
import CouponModel from "../../../models/couponModel";
import {format, endOfDay} from "date-fns";
import MarketplaceCouponsModel from "../../../models/marketplaceCouponModel";
import Select from "../../../components/controllElements/select";
import Input from "../../../components/controllElements/input";

const initialEditState = {
	isOpen: false,
	mode: 'create',
	code: '',
	discountRatio: '',
	notificationText: '',
	validUntil: new Date(),
	status: 'inactive'
};

const CouponsPage = () => {
	const [editState, setEditState] = useState(initialEditState);
	const [coupons, setCoupons] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [currentCoupon, setCurrentCoupon] = useState(null);
	const [selectedMarketId, setSelectedMarketId] = useState('all');

	const getCoupons = async () => {
		await getAllCoupons()
		.then(res => {
			setCoupons(res);

			if (currentCoupon) {
				const newCurrentCoupon = res.find(r => r.id == currentCoupon.id)
				setCurrentCoupon(newCurrentCoupon);
				setEditState({
					isOpen: true,
					mode: 'edit',
					code: newCurrentCoupon.code,
					discountRatio: newCurrentCoupon.discountRatio * 100,
					notificationText: newCurrentCoupon.notificationText,
					validUntil: new Date(newCurrentCoupon.validUntil),
					status: newCurrentCoupon.status
				});
			}
		});
	};

	useEffect(() => {
		getCoupons();
		getAllMarketplaces()
		.then(res => setMarkets(res));
	},[]);

	const changeHandler = (field, value) => {
		setEditState(p => ({...p, [field]: value}));
	};

	const submitHandler = async () => {
		if (editState.mode === 'create') {
			const newCoupon = new CouponModel();
			newCoupon.code = editState.code;
			newCoupon.status = editState.status;
			newCoupon.discountRatio = editState.discountRatio / 100;
			newCoupon.notificationText = editState.notificationText;
			newCoupon.notificationText = editState.notificationText;
			newCoupon.validUntil = endOfDay(editState.validUntil);
			await newCoupon.save()
			.then(() => {
				getCoupons();
				setEditState(initialEditState);
				setCurrentCoupon(null);
			})
		} else {
			currentCoupon.code = editState.code;
			currentCoupon.status = editState.status;
			currentCoupon.discountRatio = editState.discountRatio / 100;
			currentCoupon.notificationText = editState.notificationText;
			currentCoupon.notificationText = editState.notificationText;
			currentCoupon.validUntil = endOfDay(editState.validUntil);
			await currentCoupon.save()
			.then(() => {
				getCoupons()
				setEditState(initialEditState);
				setCurrentCoupon(null);
			})
		}
	};

	const switchStatusHandler = async (coupon) => {
		coupon.status = coupon.status === 'active' ? 'inactive' : 'active';
		await coupon.save()
		.then(() => getCoupons());
	};

	const deleteHandler = async (coupon) => {
		await coupon.destroy()
		.then(() => {
			setCurrentCoupon(null)
			getCoupons()
		});
	};

	const addMarketToCoupon = async () => {
		const market = markets.find(m => m.id === selectedMarketId);

		const currentMarketCoupon = new MarketplaceCouponsModel();
		currentMarketCoupon.marketplace = market;
		currentMarketCoupon.coupon = currentCoupon;

		await currentMarketCoupon.save({ with: ['marketplace.id', 'coupon.id']})
		.then(() => {
			setSelectedMarketId('all');
			getCoupons();
		})
	};

	const deleteMarketplaceCoupon = async (m_coupon) => {
		await m_coupon.destroy()
		.then(() => getCoupons());
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex-space-between marginBottom10">
					<h3>Купоны</h3>
					<Button
						variant='success'
						onClick={() => {
							setEditState({...initialEditState, isOpen: true, mode: 'create'})
						}}
						disabled={editState.open}
					>Создать купон</Button>
				</div>
				<p className="marginBottom20">
					После создания купона, нажмите "Редактировать" и привяжите площадки, для которых данный купон будет применим
				</p>
				{editState.isOpen && (
					<div className="block marginBottom40">
						<h3 className="marginBottom20">{editState.mode === 'create' ? 'Создание купона' : 'Редактирование купона'}</h3>
						<div className="flex-space-between">
							<Input
								label="Код купона"
								placeholder={`Например - "ЛЕТО2025"`}
								onChange={e => changeHandler('code', e.target.value)}
								value={editState.code}
								className="marginBottom20"
							/>
							<Input
								label="Размер скидки в процентах"
								placeholder="Например - 20"
								ype="number"
								onChange={e => changeHandler('discountRatio', e.target.value)}
								value={editState.discountRatio}
								className="marginBottom20"
							/>
						</div>
						<Input
							label="Текст нотификации"
							onChange={e => changeHandler('notificationText', e.target.value)}
							value={editState.notificationText}
							className="marginBottom20"
						/>
						<InputGroup style={{ flexWrap: 'nowrap'}} className="marginBottom20">
							<InputGroup.Text>Дата окончания срока действия</InputGroup.Text>
							<DatePicker
								locale="ru"
								selected={editState.validUntil}
								onChange={date => changeHandler('validUntil', date)}
								wrapperClassName="DatePikerWrapper"
								className="DatePikerInput"
								dateFormat="dd.MM.yyyy"
							/>
						</InputGroup>
						<div className="flex-space-between">
							<Button
								style={{width: '100%'}}
								variant="dark"
								onClick={() => {
									setEditState(initialEditState)
									setCurrentCoupon(null);
									setSelectedMarketId('all');
								}}
							>Отмена</Button>
							<Button
								style={{width: '100%'}}
								variant="dark"
								disabled={!editState.code || !editState.discountRatio || !editState.notificationText || !editState.validUntil}
								onClick={() => submitHandler()}
							>Сохранить</Button>
						</div>

						{editState.mode === 'edit' && (
							<>
								<h3 className="marginTop20 marginBottom10">Привязка площадок</h3>
								<Badge bg="info" className="marginBottom10">
									Выберите магазин и нажмите "Привязать"
								</Badge>
								<div className=" block marginBottom20">
									<h5 className="marginBottom10">Маркетплейсы к которым уже привязан купон</h5>
									<div className="grid5x">
										{currentCoupon.marketplaceCoupons.map(mc => (
											<div key={mc?.marketplace?.name} className="marketTag">
												{mc?.marketplace?.name}
												<button onClick={() => deleteMarketplaceCoupon(mc)}>x</button>
											</div>
										))}
									</div>
								</div>
								<div className="flex-space-between">
									<Select
										label="Маркетплейсы"
										placeholder="Выберите маркетплейс"
										onChange={e => setSelectedMarketId(e.target.value)}
										value={selectedMarketId}
										customOptions={<>
											<option value='all' disabled>Выберите маркетплейс</option>
											{markets.map(mkt => (
												<option
													value={mkt.id}
													key={`${mkt.id}_${mkt.name}`}
												>{mkt.name}</option>
											))}
										</>}
										customActions={
											<Button variant="dark" disabled={selectedMarketId === 'all'} onClick={() => addMarketToCoupon()}>
												Привязать
											</Button>
										}
									/>
								</div>
							</>
						)}
					</div>
				)}
				<div className="block tableWrapperForBigTable">
					<Table>
						<thead className="marginBottom20">
						<tr>
							<th>Код купона</th>
							<th>Размер скидки</th>
							<th>Дата окончания</th>
							<th>Статус</th>
							<th/>
						</tr>
						</thead>
						<tbody style={{ width: '100%'}}>
						{coupons.map(c => (<tr key={c.id}>
							<td>{c.code}</td>
							<td>{`${c.discountRatio * 100} %`}</td>
							<td>{format(new Date(c.validUntil), 'dd.MM.yyyy')}</td>
							<td>{c.status === 'active' ? 'Активен' : 'Неактивен'}</td>
							<td style={{ width: '450px', textAlign: 'right'}}>
								<Button
									variant="dark"
									style={{ width: '147px'}}
									onClick={() => switchStatusHandler(c)}
								>{c.status === 'active' ? 'Деактивировать' : 'Активировать'}</Button>
								<Button
									variant="dark"
									className="marginLeft10"
									onClick={() => {
										setEditState({
											isOpen: true,
											mode: 'edit',
											code: c.code,
											discountRatio: c.discountRatio * 100,
											notificationText: c.notificationText,
											validUntil: new Date(c.validUntil),
											status: c.status
										});
										setCurrentCoupon(c)
									}}
								>Редактировать</Button>
								<Button
									variant="dark"
									className="marginLeft10"
									onClick={() => deleteHandler(c)}
								>Удалить</Button>
							</td>
						</tr>))}
						</tbody>
					</Table>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default CouponsPage;