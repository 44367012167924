import {Form, InputGroup} from "react-bootstrap";

const Select = ({
	label,
	onChange,
	value,
	withValidation = false,
	error,
	errorText,
	className,
	options = [],
	customOptions,
	style,
	placeholder,
	customActions
}) => {
	return (
		<InputGroup className={className} style={style}>
			{label && (
				<InputGroup.Text>{label}</InputGroup.Text>
			)}
			<Form.Select
				aria-label={label}
				aria-placeholder={placeholder}
				onChange={e => onChange(e)}
				value={value}
				isInvalid={!!error}
			>
				{customOptions ? customOptions : (
					options.map(opt => (
						<option value={opt.value} key={opt.value}>{opt.label}</option>
					))
				)}
			</Form.Select>
			{customActions && (
				customActions
			)}
			{withValidation && (
				<Form.Control.Feedback type="invalid">
					{errorText}
				</Form.Control.Feedback>
			)}
	</InputGroup>
	)
}

export default Select;
