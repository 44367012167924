import {Button, Card} from "react-bootstrap";

const Products = ({products, selectProductHandler}) => {
	const unverifiedProducts = products.filter(p => (p.status === 'verification'));
	const publishedProducts = products.filter(p => (p.status === 'published'));

	return (
		<div className="marginTop40">
			<div className="block marginBottom20">
				<h3>Ожидают верификации</h3>
				<div className="grid6x">
					{unverifiedProducts.map(pr => (
						<Card key={pr?.unverifiedChanges?.name || pr.name} style={{ width: '100%'}}>
							{
								pr.unverifiedImageUrl || pr.imageUrl ? (
									<Card.Img
										variant="top"
										height={200}
										src={`${window.__CONFIG__.REACT_APP_BASE_URL}${pr.unverifiedImageUrl || pr.imageUrl}`}
									/>
								) : (
									<div style={{height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Изображение не найдено</div>
								)
							}
							<Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
								<Card.Title><b>{pr?.unverifiedChanges?.name || pr.name}</b></Card.Title>
								<Card.Text>{pr?.unverifiedChanges?.description || pr.description}</Card.Text>
								<Button
									variant="dark"
									onClick={() => {selectProductHandler(pr)}}
									style={{height: '38px', margin: '10px 0 0 0'}}
								>
									Просмотреть
								</Button>
							</Card.Body>
						</Card>
					))}
					{unverifiedProducts.length === 0 && (
						<p>Товаров пока нет</p>
					)}
				</div>
			</div>

			<div className="block">
				<h3>Опубликованные</h3>
				<div className="grid6x">
					{publishedProducts.map(pr => (
						<Card key={pr?.unverifiedChanges?.name || pr.name} style={{ width: '100%'}}>
							{
								pr.unverifiedImageUrl || pr.imageUrl ? (
									<Card.Img
										variant="top"
										height={200}
										src={`${window.__CONFIG__.REACT_APP_BASE_URL}${pr.unverifiedImageUrl || pr.imageUrl}`}
									/>
								) : (
									<div style={{height: '200px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Изображение не найдено</div>
								)
							}
							<Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
								<Card.Title><b>{pr?.unverifiedChanges?.name || pr.name}</b></Card.Title>
								<Card.Text>{pr?.unverifiedChanges?.description || pr.description}</Card.Text>
								<Button
									variant="dark"
									onClick={() => {selectProductHandler(pr)}}
									style={{height: '38px', margin: '10px 0 0 0'}}
								>
									Просмотреть
								</Button>
							</Card.Body>
						</Card>
					))}
					{publishedProducts.length === 0 && (
						<p>Товаров пока нет</p>
					)}
				</div>
			</div>
		</div>
	)
};

export default Products;
