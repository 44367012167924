import {InputGroup} from "react-bootstrap";
import DatePicker, {registerLocale} from "react-datepicker";
import {ru} from "date-fns/locale/ru";

const DatePickerComponent = ({
	label,
	onChange,
	value,
	style,
}) => {
	registerLocale('ru', ru);

	return (
		<InputGroup style={style}>
			<InputGroup.Text>{label}</InputGroup.Text>
			<DatePicker
				locale="ru"
				selected={value}
				onChange={(date) => onChange(date)}
				wrapperClassName="DatePikerWrapper"
				className="DatePikerInput"
				dateFormat="dd.MM.yyyy"
			/>
		</InputGroup>
	)
}

export default DatePickerComponent;
