import {useEffect, useState} from "react";
import {Table, Button, InputGroup} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import DatePicker from "react-datepicker";
import {format, startOfYesterday} from 'date-fns';
import {getMarketStats} from "../../../api/statisticsAPI";
import './statisticPage.scss';
import {useParams} from "react-router-dom";

const StatisticPage = () => {
	const { marketplaceId } = useParams();
	const [dateFrom, setDateFrom] = useState(new Date(startOfYesterday()));
	const [dateTo, setDateTo] = useState(new Date());
	const [stat, setStat] = useState(null);

	const getStat = async () => {
		await getMarketStats(
			format(dateFrom, 'yyyy-MM-dd'),
			format(dateTo, 'yyyy-MM-dd'),
			marketplaceId
		)
		.then(res => setStat(res[0]))
	};

	console.log(stat);

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				<div className="flex-space-between marginBottom20">
					<h3>Статистика</h3>
				</div>

				<div className="block flex-space-between marginBottom20">
					<InputGroup style={{ flexWrap: 'nowrap'}}>
						<InputGroup.Text>Дата начала отчёта</InputGroup.Text>
						<DatePicker
							locale="ru"
							selected={dateFrom}
							onChange={date => setDateFrom(date)}
							wrapperClassName="DatePikerWrapper"
							className="DatePikerInput"
							dateFormat="dd.MM.yyyy"
						/>
					</InputGroup>
					<InputGroup style={{ flexWrap: 'nowrap'}}>
						<InputGroup.Text>Дата окончания отчёта</InputGroup.Text>
						<DatePicker
							locale="ru"
							selected={dateTo}
							onChange={date => setDateTo(date)}
							wrapperClassName="DatePikerWrapper"
							className="DatePikerInput"
							dateFormat="dd.MM.yyyy"
						/>
					</InputGroup>
					<Button variant="dark" onClick={() => getStat()}>
						Сформировать</Button>
				</div>

				{stat && (
					<>
						<h3 className="marginBottom20">
							{`Статистика за период c ${format(dateFrom, 'dd.MM.yyyy')} по ${format(dateTo, 'dd.MM.yyyy')}`}
						</h3>
						<div className="grid3x grid3xTo1Mobile">
							<div className="block">
								<h4 className="marginBottom20">Количество заказов</h4>
								<h5>
									<b>{stat.ordersCount ? `${stat.ordersCount} заказов` : 'Информация за период отсутствует'}</b>
								</h5>
							</div>
							<div className="block">
								<h4 className="marginBottom20">Общая сумма заказов</h4>
								<h5>
									<b>{stat.totalAmountPaidForItems ? `${stat.totalAmountPaidForItems / 100} Р` : 'Информация за период отсутствует'}</b>
								</h5>
							</div>
							<div className="block">
								<h4 className="marginBottom20">Средний чек</h4>
								<h5>
									<b>{stat.averageAmountPaidForItems ? `${stat.averageAmountPaidForItems / 100} Р` : 'Информация за период отсутствует'}</b>
								</h5>
							</div>
						</div>
					</>
				)}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default StatisticPage;