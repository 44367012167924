import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";

import './notificationPage.scss';
import {useEffect, useState} from "react";
import {getAllNotificationsForUser} from "../../../api/notificationsAPI";
import {USER} from "../../../helpers/constants/localStorageConstants";
import {Alert, Button} from "react-bootstrap";
import {orderStatusBuilder} from "../../../helpers/constants/statuses";
import {format} from "date-fns";
import PaginationComponent from "../../../components/pagination/pagination";
import {useNavigate, useParams} from "react-router-dom";

const NotificationPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [notificationsList, setNotificationsList] = useState([]);
	const currentUser = JSON.parse(localStorage.getItem(USER));
	const [activePage, setActivePage] = useState(1);
	const [total, setTotal] = useState(0);

	const getNotifications = async (pageNumber = null) => {
		setNotificationsList([]);
		getAllNotificationsForUser({
			recipientId: currentUser.id,
			page: pageNumber || activePage,
		})
		.then(res => {
			setNotificationsList(res.data);
			setTotal(res.total);
		})
	};

	useEffect(() => {
		getNotifications()
	}, []);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setNotificationsList([]);
		getNotifications(pageNumber);
	};

	const markReadHandler = async (n) => {
		n.isRead = true;
		await n.save()
		.then(() => {
			getNotifications();
		})
	}

	console.log(notificationsList);

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				<div className="scrollWrapper">
					<div className="marginBottom20 marginTop20">
						{notificationsList.map(n => (
							<Alert
								variant={n.isRead ? 'secondary' : 'success' }
								onClose={() => markReadHandler(n)}
								dismissible={!n.isRead}
							>
								<div className="flex-space-between">
									<p className="marginBottom10">{`${n?.event?.params?.message} "${orderStatusBuilder(n?.event?.params?.status)}"`}</p>
									<p className="marginBottom10">{format(new Date(n?.createdAt), 'dd.MM.yyyy - HH:mm')}</p>
								</div>
								<Button
									style={{padding: 0}}
									variant="link"
									onClick={() => {
										markReadHandler(n);
										navigate(`/merchant/${marketplaceId}/orders/${n?.event?.params?.order_id}`)
									}}
								>
									Перейти к заказу
								</Button>
							</Alert>
						))}
					</div>
					<PaginationComponent
						total={total}
						limit={10}
						activePage={activePage}
						handlePageChange={(pn) => handlePageChange(pn)}
					/>
				</div>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default NotificationPage