import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup, Tabs, Tab, TabContent, Modal, Spinner} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import PaginationComponent from "../../../components/pagination/pagination";
import {getUsers, searchUsers} from '../../../api/userAPI';
import './couriersPage.scss';
import {useError} from "../../../helpers/context/errorContext";
import UserCard from "../../../components/cards/userCard/userCard";
import Input from "../../../components/controllElements/input";

const CouriersPage = () => {
	const key = 'courier';
	const navigate = useNavigate();
	const { setNewError } = useError();
	const [isShow, setShowState] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [selectedUser, setSelectedUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');

	const getUsersWithRole = async (pageNumber = null) => {
		await getUsers(key, pageNumber || activePage)
		.then(res => {
			setUsers(res.data);
			setTotal(res.total);
			setIsLoading(false);
		})
	};

	useEffect(() => {
		getUsersWithRole();
	}, [activePage]);

	const handlePageChange = (pageNumber) => {
		setIsLoading(true);
		setActivePage(pageNumber);
		setUsers([]);
		getUsersWithRole(pageNumber);
	};

	const searchHandler = async () => {
		setIsLoading(true);
		await searchUsers(key, 1, searchStr)
		.then(res => {
			setUsers(res.data);
			setTotal(res.total);
			setIsLoading(false);
		})
	};

	const deleteHandler = async () => {
		await selectedUser.destroy()
		.then(r => {
			if (r) {
				getUsersWithRole();
				closeHandler();
			} else {
				setNewError({
					isShow: true,
					text: selectedUser?.errors?.base?.fullMessage
				});
				closeHandler();
			}
		})
	};

	const closeHandler = () => {
		setShowState(false);
		setSelectedUser(null);
	};

	const cancelSearchHandler = async () => {
		setSearchStr('');
		getUsersWithRole(1);
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Modal show={isShow} onHide={() => closeHandler()}>
						<Modal.Header closeButton>
							<Modal.Title>Удаление курьера</Modal.Title>
						</Modal.Header>
						<Modal.Body>{`Вы действительно хотите удалить 
						${selectedUser?.name || ''} ${selectedUser?.surname || ''} ${selectedUser?.patronym || ''}`}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => closeHandler()}>
								Отмена
							</Button>
							<Button variant="primary" onClick={() => deleteHandler()}>
								Удалить
							</Button>
						</Modal.Footer>
					</Modal>
					<div className="marginBottom40">
						<Input
							label="Поиск"
							placeholder="Введите курьера"
							onChange={e => setSearchStr(e.target.value)}
							value={searchStr}
							customActions={<>
								<Button
									variant="dark"
									onClick={() => searchHandler()}
								>Найти</Button>
								{searchStr && (
									<Button
										variant="dark"
										onClick={() => cancelSearchHandler()}
									>Сбросить</Button>
								)}
							</>}
						/>
					</div>
					{isLoading
						? (
							<Spinner style={{position: "absolute", top: '50%', left: '50%', marginTop: '40px'}} />
						)
						: (
							users.length > 0
								? (
									<>
										<div className="grid5x grid5x_couriers marginTop20">
											{users.map(user => (
												<UserCard
													isCourierCard
													key={user.id}
													user={user}
													keyRole={key}
													customActionButton={
														<>
															<Button style={{width: '100%'}} variant="dark" onClick={() => {navigate(`/admin/couriers/${user.id}`)}}>
																Просмотреть профиль
															</Button>
															<Button style={{width: '100%'}} variant="dark" onClick={() => {navigate(`/admin/chats?with=courier`)}}>
																Связаться с курьером
															</Button>
														</>
													}
													deleteHandler={(user) => {
														setShowState(true);
														setSelectedUser(user);
													}}
												/>
											))}
										</div>
										<PaginationComponent
											total={total}
											activePage={activePage}
											handlePageChange={(pn) => handlePageChange(pn)}
										/>
									</>
								)
								: (
									<h2 className="flex-center">Ничего не найдено</h2>
								)
						)
					}
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default CouriersPage;