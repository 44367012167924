import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Tabs, Tab, TabContent, Card, Spinner} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import PaginationComponent from "../../../components/pagination/pagination";
import {getMarketplaces, searchMarketplaces, filteredMarketplaces} from '../../../api/marketplaceAPI';
import {getAllTags} from '../../../api/tagsAPI';
import './marketplacesPage.scss';
import Tags from "../../../components/tags/tags";
import {getAllCities} from "../../../api/cityAPI";
import Input from "../../../components/controllElements/input";
import Select from "../../../components/controllElements/select";

const MarketplacesPage = () => {
	const navigate = useNavigate();
	const [cities, setCities] = useState([]);
	const [city, setCity] = useState('all');
	const [isLoading, setIsLoading] = useState(true);
	const [total, setTotal] = useState(0);
	const [key, setKey] = useState('verification');
	const [tags, setTags] = useState([]);
	const [activeItem, setActiveItem] = useState([]);
	const [initialMarketplaces, setInitialMarketplaces] = useState([]);
	const [marketplaces, setMarketplaces] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');

	const getAllMarketplaces = async (pageNumber = null) => {
		await getMarketplaces(key, pageNumber || activePage, city)
		.then(res => {
			setMarketplaces(res.data);
			setInitialMarketplaces(res.data);
			setTotal(res.total);
			setIsLoading(false);
		})
	};

	const filterHandler = async (id) => {
		await filteredMarketplaces(key, 1, id)
		.then(res => {
			setMarketplaces(res.data);
			setTotal(res.total);
			setIsLoading(false);
		})
	}

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res));
	};

	useEffect(() => {
		getAllMarketplaces();
	}, [activePage, key, city]);

	useEffect(() => {
		getTags();
	},[]);

	useEffect(() => {
		getAllCities().then(
			(res) => setCities(res)
		)
	}, []);


	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setMarketplaces([]);
		getAllMarketplaces(pageNumber);
	};

	const searchHandler = async () => {
		await searchMarketplaces(key, 1, searchStr)
		.then(res => {
			setMarketplaces(res.data);
			setTotal(res.total)
		})
	};

	const tabChangeHandler = (k) => {
		setMarketplaces([]);
		setIsLoading(true);
		setKey(k);
		setActivePage(1);
		setActiveItem(null);
	};

	const onClickTagHandler = (tag) => {
		if (activeItem?.id === tag?.id) {
			setIsLoading(true);
			getAllMarketplaces();
			setActiveItem(null);
		} else {
			setIsLoading(true);
			filterHandler(tag.id)
			setActiveItem(tag);
		}
	}

	const cancelSearchHandler = async () => {
		setSearchStr('');
		getAllMarketplaces(1);
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<div className="marginBottom20">
						<Input
							label="Поиск"
							placeholder="Введите название бизнеса"
							onChange={e => setSearchStr(e.target.value)}
							value={searchStr}
							customActions={<>
								<Button
									variant="dark"
									onClick={() => searchHandler()}
								>Найти</Button>
								{searchStr && (
									<Button
										variant="dark"
										onClick={() => cancelSearchHandler()}
									>Сбросить</Button>
								)}
							</>}
						/>
					</div>
					<div className="marginBottom40">
						<Tags
							items={tags}
							activeItem={activeItem}
							onClickHandler={(tag) => onClickTagHandler(tag)}
						/>
						<div className="marginTop10 flex">
							<Select
								label="Выберите город для фильтрации по городу"
								value={city}
								onChange={(c) => {
									setCity(c.target.value);
								}}
								customOptions={<>
									<option value='all' disabled>Выберите город</option>
									{cities.map(city => (
										<option key={city.name} value={city.id}>{city.name}</option>
									))}
								</>}
							/>
							{city !== 'all' && (
								<Button
									variant="dark"
									onClick={() => {
										setCity('all');
									}}
								>
									Сбросить
								</Button>
							)}
						</div>
					</div>

					<Tabs
						activeKey={key}
						onSelect={(k) => {
							if (key !== k) {
								tabChangeHandler(k)
							}
						}}
					>
						<Tab eventKey="verification" title="Ожидающие верификации"/>
						<Tab eventKey="published" title="Опубликованные"/>
					</Tabs>
					<TabContent style={{position: 'relative'}}>
						{marketplaces.length > 0
							? (
								<>
									<div className="grid5x marginTop20">
										{marketplaces.map(market => (
											<Card key={market.name} style={{ width: '100%'}}>
												<Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
													<Card.Title><b>{market?.unverifiedChanges?.name || market.name}</b></Card.Title>
													<Card.Text>{market?.unverifiedChanges?.description || market.description}</Card.Text>
													<Button
														className="marginTop20"
														variant="dark"
														onClick={() => {navigate(`/admin/marketplaces/${market.id}`)}}
														style={{height: '38px'}}
													>
														Просмотреть
													</Button>
												</Card.Body>
											</Card>
										))}
									</div>
									<PaginationComponent
										total={total}
										activePage={activePage}
										handlePageChange={(pn) => handlePageChange(pn)}
									/>
								</>
							)
							: isLoading
								? (<Spinner style={{position: "absolute", top: '50%', left: '50%', marginTop: '40px'}} />)
								: (
									<div className="flex-center marginTop60">
										<h1>Бизнесы не найдены</h1>
									</div>
								)
						}
					</TabContent>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default MarketplacesPage;