import './globalLoader.scss'
import {Spinner} from "react-bootstrap";

const GlobalLoader = () => {
	return (
		<div className="GlobalLoaderRoot">
			<div className="GlobalLoaderBlock">
				<Spinner className="GlobalLoaderSpinner" />
				<b className="GlobalLoaderText" >Дождитесь завершения операции</b>
			</div>
		</div>
	)
};

export default GlobalLoader;