import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Tabs, Tab, TabContent, Modal, Spinner} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import PaginationComponent from "../../../components/pagination/pagination";
import {getUsers, searchUsers} from '../../../api/userAPI';
import './usersPage.scss';
import {useError} from "../../../helpers/context/errorContext";
import UserCard from "../../../components/cards/userCard/userCard";
import Input from "../../../components/controllElements/input";

const UsersPage = () => {
	const navigate = useNavigate();
	const { setNewError } = useError();
	const [isLoading, setisLoading] = useState(true);
	const [isShow, setShowState] = useState(false);
	const [total, setTotal] = useState(0);
	const [selectedUser, setSelectedUser] = useState(null);
	const [key, setKey] = useState('merchant');
	const [users, setUsers] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');

	const getUsersWithRole = async (pageNumber = null) => {
		await getUsers(key, pageNumber || activePage)
		.then(res => {
			setUsers(res.data);
			setTotal(res.total);
			setisLoading(false);
		})
	};

	useEffect(() => {
		getUsersWithRole();
	}, [activePage, key]);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setUsers([]);
		setisLoading(true);
		getUsersWithRole(pageNumber);
	};

	const searchHandler = async () => {
		setisLoading(true);
		await searchUsers(key, 1, searchStr)
		.then(res => {
			setUsers(res.data);
			setTotal(res.total);
			setisLoading(false);
		})
	};

	const deleteHandler = async () => {
		await selectedUser.destroy()
		.then(r => {
			if (r) {
				getUsersWithRole();
				closeHandler();
			} else {
				setNewError({
					isShow: true,
					text: selectedUser?.errors?.base?.fullMessage
				});
				closeHandler();
			}
		})
	};

	const closeHandler = () => {
		setShowState(false);
		setSelectedUser(null);
	};

	const tabChangeHandler = (k) => {
		setUsers([]);
		setKey(k);
		setActivePage(1);
		setisLoading(true);
	};

	const cancelSearchHandler = async () => {
		setSearchStr('');
		getUsersWithRole(1);
	};

	const deactivateHandler = async (user) => {
		user.isDeactivated = !user.isDeactivated;
		await user.save()
		.then((r) => {
			if (r) {
				getUsersWithRole()
			} else {
				setNewError({
					isShow: true,
					text: user?.errors?.base?.fullMessage
				});
			}
		})
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Modal show={isShow} onHide={() => closeHandler()}>
						<Modal.Header closeButton>
							<Modal.Title>Удаление пользователя</Modal.Title>
						</Modal.Header>
						<Modal.Body>{`Вы действительно хотите удалить 
						${selectedUser?.name || ''} ${selectedUser?.surname || ''} ${selectedUser?.patronym || ''}`}
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={() => closeHandler()}>
								Отмена
							</Button>
							<Button variant="primary" onClick={() => deleteHandler()}>
								Удалить
							</Button>
						</Modal.Footer>
					</Modal>
					<div className="grid2x marginBottom40">
						<Input
							label="Поиск"
							placeholder="Введите пользователя"
							onChange={e => setSearchStr(e.target.value)}
							value={searchStr}
							customActions={<>
								<Button
									variant="dark"
									onClick={() => searchHandler()}
								>Найти</Button>
								{searchStr && (
									<Button
										variant="dark"
										onClick={() => cancelSearchHandler()}
									>Сбросить</Button>
								)}
							</>}
						/>
						<Button
							variant="dark"
							onClick={() => {navigate('/admin/users/create')}}
						>Добавить пользователя</Button>
					</div>
					<Tabs
						activeKey={key}
						onSelect={(k) => {
							if (key !== k) {
								tabChangeHandler(k)
							}
						}}
					>
						<Tab eventKey="merchant" title="Продавцы"/>
						<Tab eventKey="courier" title="Курьеры"/>
						<Tab eventKey="user" title="Клиенты"/>
						<Tab eventKey="admin" title="Администраторы"/>
					</Tabs>
					<TabContent style={{position: 'relative'}}>
						{isLoading ? (
							<Spinner style={{position: "absolute", top: '50%', left: '50%', marginTop: '40px'}} />
						) : (
							users.length > 0
								? (
									<>
										<div className="grid5x marginTop20">
											{users.map(user => (
												<UserCard
													key={user.id}
													user={user}
													keyRole={key}
													deactivateHandler={(user) => deactivateHandler(user)}
													deleteHandler={(user) => {
														setShowState(true);
														setSelectedUser(user);
													}}
												/>
											))}
										</div>
										<PaginationComponent
											total={total}
											activePage={activePage}
											handlePageChange={(pn) => handlePageChange(pn)}
										/>
									</>
								)
								: (<div style={{
									textAlign:"center",
									marginTop: "20px"
								}}>Пользователей не найдено</div>)
						)}
					</TabContent>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default UsersPage;