import {useEffect, useState} from "react";
import { ReactSortable } from "react-sortablejs";
import {Button, Form, InputGroup, Badge} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {getAllQuestions} from "../../../api/faqAPI";
import FAQModel from "../../../models/faqModel";
import './faqPage.scss';
import Input from "../../../components/controllElements/input";

const FaqPage = () => {
	const [initialQuestions, setInitialQuestions] = useState([]);
	const [questions, setQuestions] = useState([]);
	const [currentItem, setCurrentItem] = useState(null);
	const [isOpen, setOpen] = useState(false);
	const [mode, setMode] = useState('create');
	const [data, setData] = useState({
		answer: '',
		question: '',
		position: '',
	});

	const getQuestions = async () => {
		await getAllQuestions()
		.then(res => {
			setQuestions(res);
			setInitialQuestions(res);
			setCurrentItem(null);
			setOpen(false);
			setMode('create');
			setData({
				answer: '',
				question: '',
				position: '',
			})
		})
	}

	useEffect(() => {
		getQuestions();
	}, []);

	const submitHandler = async () => {
		if(mode === 'create') {
			const currentItem = new FAQModel();
			currentItem.answer = data.answer;
			currentItem.question = data.question;
			currentItem.position = initialQuestions.length + 1;

			await currentItem.save()
			.then(() => {
				getQuestions();
			})
		} else {
			currentItem.answer = data.answer;
			currentItem.question = data.question;

			await currentItem.save()
			.then(() => {
				getQuestions();
			})
		}
	}

	const deleteHandler = async (i) => {
		await i.destroy()
		.then(() => {
			getQuestions();
		})
	};

	const sortHandler = async (oldIndex, newIndex) => {
		const currentItem = initialQuestions.find(i => i.position === oldIndex + 1);
		currentItem.position = newIndex + 1;
		await currentItem.save()
		.then(() => {
			getQuestions()
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div className="flex-space-between marginBottom20">
					<h3>Вопрос / Ответ</h3>
					<Button variant='dark' onClick={() => {
						setMode('create');
						setOpen(true);
					}}>Создать</Button>
				</div>
				{isOpen && (
					<div className="block marginBottom20">
						<h5 className="marginBottom20">{mode === 'create' ? 'Создание вопроса' : 'Редактирование вопроса'}</h5>

						<Input
							className='marginBottom10'
							label="Вопрос"
							placeholder="Введите вопрос"
							value={data.question}
							onChange={e => setData(p => ({ question: e.target.value, answer: p.answer, position: p.position}))}
						/>
						<Input
							className='marginBottom10'
							label="Ответ"
							placeholder="Введите ответ"
							value={data.answer}
							onChange={e => setData(p => ({ answer: e.target.value, question: p.question, position: p.position}))}
						/>

						<div className="flex-space-between">
							<Button
								variant="dark"
								style={{width: '100%'}}
								disabled={!data.answer || !data.question}
								onClick={() => submitHandler()}
							>
								Сохранить</Button>
							<Button
								variant="dark"
								style={{width: '100%'}}
								onClick={() => {
									setMode('create');
									setOpen(false);
									setCurrentItem(null);
									setData({
										answer: '',
										question: '',
									});
								}}
							>
								Отменить</Button>
						</div>
					</div>
				)}
				<div className='block'>
					<h6 className="marginBottom20">Для изменения порядка вопросов, нажмите на вопрос, не отпуская потяните и поставте на нужное место</h6>
					<ReactSortable
						dragClass="sortableDrag"
						list={questions}
						setList={() => {}}
						animation="200"
						easing="ease-out"
						onEnd={(e) => {
							sortHandler(e.oldIndex, e.newIndex)
						}}
					>
						{questions.map((item, idx) => (
							<div className="Item flex-space-between" key={item.answer}>
								<p>{`${item.position}. ${item.question}`}</p>
								<div className='flex'>
									<Button
										variant="dark"
										onClick={() => {
											setCurrentItem(item);
											setMode('edit');
											setOpen(true);
											setData({
												answer: item.answer,
												question: item.question
											})
										}}
									>
										Редактировать</Button>
									<Button variant="dark" className="marginLeft10" onClick={() => deleteHandler(item)}>Удалить</Button>
								</div>
							</div>
						))}
					</ReactSortable>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default FaqPage;