import OrderModel from "../models/orderModel";

const getAllOrders = async (page) => {
	const res = await OrderModel
	.page(page)
	.per(15)
	.includes(['pickupLocation', 'marketplace'])
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};


const getAllOrdersByDeliveryType = async (page, deliveryType, hasCourier, marketId) => {
	let filters;
	if (deliveryType === 'all') {
		filters = {
			marketplaceId: marketId === 'all' ? null : marketId,
		}
	} else {
		filters = !hasCourier ? deliveryType === 'courier' ? {
			deliveryType: deliveryType,
			hasCourier: hasCourier,
			marketplaceId: marketId === 'all' ? null : marketId,
			status: ['awaiting_processing', 'processing', 'awaiting_pickup']
		} : {
			deliveryType: deliveryType,
			marketplaceId: marketId === 'all' ? null : marketId,
		} : {
			deliveryType: deliveryType,
			marketplaceId: marketId === 'all' ? null : marketId,
			hasCourier: hasCourier
		};
	}

	const res = await OrderModel
	.where(filters)
	.page(page)
	.includes(['pickupLocation', 'marketplace'])
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getOrdersForMarketplace = async (marketplaceId, page) => {
	const res = await OrderModel
	.where({marketplaceId: marketplaceId})
	.page(page)
	.includes(['pickupLocation', 'marketplace'])
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getOrdersForCourier = async (courierId, page) => {
	const res = await OrderModel
	.where({courierId: courierId})
	.page(page)
	.includes(['pickupLocation', 'marketplace'])
	.per(10)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getOrdersForUser = async (userId, page) => {
	const res = await OrderModel
	.where({userId: userId})
	.page(page)
	.per(10)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getFilterOrdersForMarketplace = async (marketplaceId, status) => {
	const res = await OrderModel
	.where({marketplaceId: marketplaceId, status: status})
	.includes(['pickupLocation', 'marketplace'])
	.all();
	return res.data;
};

const searchAllOrdersByNumber = async (orderNumber) => {
	const res = await OrderModel
	.where({publicUid: {match: orderNumber}})
	.includes(['pickupLocation', 'marketplace'])
	.all();
	return res.data;
};

const searchAllOrdersByDeliveryType = async (orderNumber, page, deliveryType, hasCourier) => {
	const filters = !hasCourier ? deliveryType === 'courier' ? {
		deliveryType: deliveryType,
		hasCourier: hasCourier,
		status: ['awaiting_processing', 'processing', 'awaiting_pickup'],
		publicUid: {match: orderNumber}
	} : {
		deliveryType: deliveryType,
		publicUid: {match: orderNumber}
	} : {
		deliveryType: deliveryType,
		hasCourier: hasCourier,
		publicUid: {match: orderNumber}
	};

	const res = await OrderModel
	.where(filters)
	.page(page)
	.per(15)
	.includes(['pickupLocation', 'marketplace'])
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const searchOrdersByNumber = async (marketplaceId, orderNumber) => {
	const res = await OrderModel
	.where({marketplaceId: marketplaceId, publicUid: {match: orderNumber}})
	.includes(['pickupLocation', 'marketplace'])
	.all();
	return res.data;
};

const getOrder = async (orderId) => {
	const res = await OrderModel
	.includes(['pickupLocation', 'user', 'courier', 'marketplace','userAddress', {order_items: 'product'}, {coupon_usage: 'coupon'}])
	.find(orderId);
	return res.data;
};

export {
	getAllOrders,
	getOrdersForCourier,
	getAllOrdersByDeliveryType,
	getOrdersForMarketplace,
	getFilterOrdersForMarketplace,
	searchAllOrdersByNumber,
	searchAllOrdersByDeliveryType,
	searchOrdersByNumber,
	getOrder,
	getOrdersForUser
}