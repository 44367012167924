import {useEffect, useState} from "react";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import PaginationComponent from "../../../components/pagination/pagination";
import {Alert, Tab, Tabs, Button} from "react-bootstrap";
import {getAllNotificationsForUser} from "../../../api/notificationsAPI";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {USER} from "../../../helpers/constants/localStorageConstants";
import {orderStatusBuilder} from "../../../helpers/constants/statuses";
import {useNavigate} from "react-router-dom";
import {format} from "date-fns";

const NotificationPage = () => {
	const navigate = useNavigate();
	const currentUser = JSON.parse(localStorage.getItem(USER));
	const [notificationsList, setNotificationsList] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [total, setTotal] = useState(0);
	const [key, setKey] = useState('OrderStatusNotifier::Notification');

	const getNotifications = async (pageNumber = null) => {
		setNotificationsList([]);
		getAllNotificationsForUser({
			recipientId: currentUser.id,
			page: pageNumber || activePage,
			type: key
		})
		.then(res => {
			setNotificationsList(res.data);
			setTotal(res.total);
		})
	};

	useEffect(() => {
		getNotifications()
	}, [key]);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		setNotificationsList([]);
		getNotifications(pageNumber);
	};

	const markReadHandler = async (n) => {
		n.isRead = true;
		await n.save()
		.then(() => {
			getNotifications();
		})
	}

	console.log(notificationsList);

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<Tabs
					activeKey={key}
					onSelect={(k) => {
						if (key !== k) {
							setKey(k)
						}
					}}
				>
					<Tab eventKey="OrderStatusNotifier::Notification" title="Уведомления о заказах"/>
					<Tab eventKey="Admin::CourierAvailabilityStatusNotifier::Notification" title="Уведомления о курьерах"/>
				</Tabs>
				<div className="marginBottom20 marginTop20">
					{notificationsList.map(n => (
						<Alert
							variant={n.isRead ? 'secondary' : 'success' }
							onClose={() => markReadHandler(n)}
							dismissible={!n.isRead}
						>
							<div className="flex-space-between">
								<p className="marginBottom10">{`${n?.event?.params?.message} "${orderStatusBuilder(n?.event?.params?.status)}"`}</p>
								<p className="marginBottom10">{format(new Date(n?.createdAt), 'dd.MM.yyyy - HH:mm')}</p>
							</div>
							{key === 'OrderStatusNotifier::Notification' && (
								<Button
									style={{padding: 0}}
									variant="link"
									onClick={() => {
										markReadHandler(n);
										navigate(`/admin/orders/${n?.event?.params?.order_id}`)
									}}
								>
									Перейти к заказу
								</Button>
							)}
						</Alert>
					))}
				</div>
				<PaginationComponent
					total={total}
					limit={10}
					activePage={activePage}
					handlePageChange={(pn) => handlePageChange(pn)}
				/>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
};

export default NotificationPage;

