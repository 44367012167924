import {useState} from "react";
import Input from '../../components/controllElements/input';
import Select from '../../components/controllElements/select';
import {Button, Col, Row, Container} from 'react-bootstrap'
import {AdminPanelRolesArray, MERCHANT_ROLE} from "../../helpers/constants/roles";
import {login} from '../../api/authAPI';
import './loginPage.scss';

const LoginPage = () => {
	const [error, setError] = useState(null);
	const [formData, setFormData] = useState({
		login: '',
		password: '',
		role: MERCHANT_ROLE,
	});

	const submitHandler = () => {
		login(
			formData.login,
			formData.password,
			formData.role,
			(err) => setError(err)
		)
	};

	return (
		<div className="Root">
			<Container>
				<Row>
					<Col>
						<Input
							label="Почта"
							onChange={e => {
								setFormData(p => ({login: e.target.value, role: p.role, password: p.password}))
							}}
							value={formData.login}
							withValidation
							error={!!error}
							errorText="Неверная почта или пароль"
						/>
						<Input
							label="Пароль"
							onChange={e => {
								setFormData(p => ({login: p.login, role: p.role, password: e.target.value}))
							}}
							value={formData.password}
							type="password"
							withValidation
							error={!!error}
							errorText="Неверная почта или пароль"
							className="marginTop20 marginBottom20"
						/>
						<Select
							style={{width: '100%'}}
							label="Роль"
							placeholder="Выберите роль"
							onChange={e => {
								setFormData(p => ({login: p.login, role: e.target.value, password: p.password }));
							}}
							value={formData.role}
							withValidation
							error={!!error}
							options={AdminPanelRolesArray}
							errorText="Убедитесь, что выбрали корректную роль"
						/>
					</Col>
				</Row>
				<Row>
					<Col className="flex-center">
						<Button
							variant="primary"
							className="marginTop20"
							disabled={!formData.login || !formData.password}
							onClick={() => submitHandler()}
						>Войти</Button>
					</Col>
				</Row>
			</Container>
		</div>
	)
}

export default LoginPage;