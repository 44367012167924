import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const AddressesModel = ApplicationRecord.extend({
	static: { jsonapiType: "addresses" },
	attrs: {
		id: attr(),
		street: attr(),
		house: attr(),
		building: attr(),
		entrance: attr(),
		doorCode: attr(),
		apartment: attr(),
		floor: attr(),
		addressLine1: attr(),
		addressLine2: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),

		city: belongsTo(),
		user: belongsTo(),
	}
})

export default AddressesModel