import {format} from "date-fns";

const MessageItem = ({
	id,
	text = 'текст сообщения',
	date = new Date(),
	isYours
}) => {

	return (
		<div id={id} className="activeChatRootMessagesWrapper" style={{justifyContent: isYours ? 'flex-end' : 'flex-start'}}>
			<div className="activeChatRootMessagesItem" style={{background: isYours ? 'lightblue' : ''}}>
				<p>{text}</p>
				<span>{format(new Date(date), 'dd.MM.yyyy - HH:mm')}</span>
			</div>
		</div>
	)
};

export default MessageItem
