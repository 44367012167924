import {useEffect, useState} from 'react';
import { format } from 'date-fns';
import {useNavigate} from "react-router-dom";
import {Table, Button, Spinner, Tab, Tabs} from "react-bootstrap";
import {
	getAllOrdersByDeliveryType,
	searchAllOrdersByNumber
} from "../../../api/orderAPI";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {OREDER_STATUSES, DELIVERE_STATUSES} from "../../../helpers/constants/statuses";
import PaginationComponent from "../../../components/pagination/pagination";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import Input from "../../../components/controllElements/input";
import {getAllMarketplaces} from "../../../api/marketplaceAPI";
import Select from "../../../components/controllElements/select";

const OrdersPage = () => {
	const navigate = useNavigate();
	const [orders, setOrders] = useState([]);
	const [markets, setMarkets] = useState([]);
	const [selectedMarket, setSelectedMarket] = useState('all')
	const [key, setKey] = useState('all');
	const [total, setTotal] = useState(0);
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');
  
	const getOrders = async (pageNumber = null, lkey = null, lmarketId = null) => {
		await getAllOrdersByDeliveryType(pageNumber || activePage, lkey || key, false, lmarketId || selectedMarket)
		.then(res => {
			setOrders(res.data);
			setTotal(res.total);
		})
	};

	const getMarketPlaces = async () => {
		await getAllMarketplaces()
		.then(res => setMarkets(res))
	};

	useEffect(() => {
		getOrders();
		getMarketPlaces();
	}, []);

	const marketChangeHandler = async (id) => {
		setSelectedMarket(id);
		await getAllOrdersByDeliveryType(
			1,
			key === 'courier_done' ? 'courier' : key,
			key === 'courier_done' ? true : key === 'self_pickup' ? null : false,
			id)
		.then(res => {
			setOrders(res.data);
			setTotal(res.total);
		})
	}

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		getOrders(pageNumber);
	};

	const searchHandler = async () => {
		await searchAllOrdersByNumber(searchStr)
		.then(res => {
			setOrders(res);
		})
	};

	const resetHandler = () => {
		setSearchStr('');
		getOrders(1, 'all', 'all');
		setKey('all');
		setSelectedMarket('all');
	};

	const setTabHandler = async (key) => {
		setKey(key);

		if (key === 'all') {
			await getAllOrdersByDeliveryType(1, key, false, selectedMarket)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}

		if (key === 'courier') {
			await getAllOrdersByDeliveryType(1, key, false, selectedMarket)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}

		if (key === 'courier_done') {
			await getAllOrdersByDeliveryType(1, 'courier', true, selectedMarket)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}

		if (key === 'self_pickup') {
			await getAllOrdersByDeliveryType(1, key, null, selectedMarket)
			.then(res => {
				setOrders(res.data);
				setTotal(res.total);
			})
		}
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{orders
					? orders.length > 0
						? (
							<>
								<Input
									className="marginBottom10"
									label="Поиск"
									placeholder="Введите номер заказа"
									onChange={e => setSearchStr(e.target.value)}
									value={searchStr}
									customActions={<>
										<Button
											variant="dark"
											onClick={() => searchHandler()}
										>Найти</Button>
									</>}
								/>

								<Select
									className="marginBottom40"
									label="Маркетплейс"
									placeholder="Выберите маркетплейс"
									onChange={e => marketChangeHandler(e.target.value)}
									value={selectedMarket}
									customOptions={<>
										<option value='all'>Все маркетплейсы</option>
										{
											markets.map((m) => (
												<option key={`${m.id}_${m.name}`} value={m.id}>{m.name}</option>
											))
										}
									</>}
								/>

								<Tabs
									activeKey={key}
									onSelect={(k) => {
										if (key !== k) {
											setTabHandler(k)
										}
									}}
									className="marginBottom20"
								>
									<Tab eventKey="all" title="Все заказы"/>
									<Tab eventKey="courier" title="Заказы без курьера"/> {/* заказы без курьера - со статусами awaiting_processing,processing,awaiting_pickup */}
									<Tab eventKey="courier_done" title="Заказы с назначеным курьером"/> {/* заказы самовывоз у которых есть курьер */}
									<Tab eventKey="self_pickup" title="Самовывоз"/> {/* заказы самовывоз */}
								</Tabs>
								<div className="tableWrapper">
									<Table>
										<thead>
										<tr>
											<th>Номер заказа</th>
											<th>Статус заказа</th>
											<th>Тип доставки</th>
											<th>Дата создания заказа</th>
											<th>Площадка</th>
											<th>Адрес выдачи</th>
										</tr>
										</thead>
										<tbody>
										{orders.map(order => (
											<tr key={order.id}>
												<td
													onClick={() => navigate(`/admin/orders/${order.id}`)}
													style={{textDecoration: 'underline', cursor: 'pointer'}}
												>
													{order.publicUid || 'Номер отсутствует'}
												</td>
												<td>{OREDER_STATUSES[order.status].translate}</td>
												<td>{DELIVERE_STATUSES[order.deliveryType].translate}</td>
												<td>{format(new Date(order.createdAt), 'dd.MM.yyyy - HH:mm')}</td>
												<td>{order?.marketplace?.name || ''}</td>
												<td>{order?.pickupLocation?.address || 'Адрес выдачи не указан'}</td>
											</tr>
										))}
										</tbody>
									</Table>
									<PaginationComponent
										total={total}
										activePage={activePage}
										handlePageChange={(pn) => handlePageChange(pn)}
									/>
								</div>
							</>
						)
						: (
							<div className="flex-column-center">
								<h1 style={{display: 'block'}}>Заказов пока нет</h1>
								<Button
									variant="dark"
									className="marginTop20"
									onClick={() => resetHandler()}
								>Отмена</Button>
							</div>
						)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrdersPage