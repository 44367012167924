import {attr, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const CouponUsageModel = ApplicationRecord.extend({
	static: { jsonapiType: "coupon_usages" },
	attrs: {
		id: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),

		order: belongsTo(),
		user: belongsTo(),
		coupon: belongsTo(),
	}
})

export default CouponUsageModel