import {useState} from "react";
import {Button, Badge } from "react-bootstrap";
import '../marketplacePage.scss';
import Input from "../../../../components/controllElements/input";
import Select from "../../../../components/controllElements/select";

const About = ({
	tags,
	market,
	rating,
	changeRatingHandler,
	submitRating,
	rejectHandler,
	approveHandler,
	unpublishHandler,
 	deleteMarketTagHandler,
	addMarketTagHandler,
	switchTypeHandler,
 	saveLegalDataHandler
}) => {
	const [selectedTag, setSelectedTag] = useState('all');
	const [localFormData, setFormData] = useState({
		organization_info: market?.legalData?.organization_info || '',
		bank_info: market?.legalData?.bank_info || '',
		email: market?.legalData?.email || '',
		phone: market?.legalData?.phone || '',
	});
	const [error, setError] = useState(false);
	const rootTags = tags.filter(t => t.rootTag);
	const marketRootTags = market?.marketplaceTags.filter(t => t.tag.rootTag);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const saveDataHandler = () => {
		const EMAIL_REGEXP = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;


		if (!EMAIL_REGEXP.test(localFormData.email)) {
			setError(true)
		} else {
			saveLegalDataHandler(localFormData);
			setError(false);
		}
	};

	return (
		<>
			<div className="marginTop40">
				<div className="block marginBottom10">
					<p>{`Город площадки: ${market?.city?.name}`}</p>
				</div>
				<div className="block marginBottom10">
					<h5 className="marginBottom10"><b>Форма отображения</b></h5>
					<div className="flex">
						<p className="marginBottom20">
							<Badge bg="danger">(ОБЯЗАТЕЛЬНО)</Badge>
							{` Выберите как будет отображаться данный бизнес в мобильном приложении.`}
						</p>
					</div>
					<div className="flex flex-wrap">
						{rootTags.map(t => (
							<Button
								className="marginRight10"
								key={t.name}
								onClick={() => switchTypeHandler(t)}
								variant={marketRootTags.find(mrt => mrt.tag.id === t.id) ? "dark" : "outline-secondary"}
							>{t.name}</Button>
						))}
					</div>
				</div>
				<div className="block marginBottom10">
					<h5 className="marginBottom20"><b>Рейтинг</b></h5>
					<p className="marginBottom20">Выставте рейтинг для данного магазина <br/>(например: <b>4.6</b>) и нажмите "Сохранить рейтинг"</p>

					<div className="flex-space-between flex-wrap">
						<Input
							label="Текущий рейтинг магазина"
							type='number'
							placeholder="Введите оценку"
							value={rating.score}
							onChange={e => changeRatingHandler(e.target.value)}
						/>
						<Button
							style={{ width: '300px'}}
							variant='dark'
							disabled={rating.score == market.rating.score}
							onClick={() => submitRating()}
						>Сохранить рейтинг</Button>
					</div>
				</div>
				<div className="block marginBottom10">
					<h5 className="marginBottom20"><b>Данные о бизнесе</b></h5>
					<Input
						label="Реквизиты организации"
						placeholder="ИНН, КПП и далее по форме"
						value={localFormData.organization_info}
						onChange={e => changeHandler('organization_info', e.target.value)}
					/>
					<Input
						label="Банковские реквизиты организации"
						placeholder="Введите банковские реквизиты организации"
						value={localFormData.bank_info}
						onChange={e => changeHandler('bank_info', e.target.value)}
					/>

					<div className="grid2x ">
						<Input
							label="Email для связи"
							placeholder="Введите email для связи"
							value={localFormData.email}
							onChange={e => changeHandler('email', e.target.value)}
							withValidation
							error={error}
							errorText="Некорректный email"
						/>
						<Input
							label="Телефон для связи"
							placeholder="Введите телефон для связи"
							value={localFormData.phone}
							onChange={e => {
								if (e.target.value.length <= 11) {
									changeHandler('phone', e.target.value.replace(/\D/, ''))
								}
							}}
						/>
					</div>
					<Button variant="dark" style={{width: '100%'}} onClick={() => saveDataHandler()}>Сохранить изменения</Button>
				</div>
				<div className="block marginBottom10">
					<h5 className="marginBottom20"><b>Теги</b></h5>
					<div className='grid2x'>
						<div className="flex flex-wrap">
							{market?.marketplaceTags?.length === 0 ? (
								<p>У бизнеса нет тегов</p>
							) : (
								market?.marketplaceTags.map(t => (!t.tag.rootTag && (
									<div className="ActiveTag">
										<b>{t.tag.name}</b>
										<Button variant="outline-danger" onClick={() => deleteMarketTagHandler(t)}>Удалить</Button>
									</div>
								)))
							)}
						</div>
						<div className='flex flex-wrap'>
							<Select
								label="Теги"
								placeholder="Выберите тег"
								onChange={e => setSelectedTag(e.target.value)}
								value={selectedTag}
								customOptions={<>
									<option value={'all'} disabled>Выберите тег</option>
									{tags.filter(t => !t.rootTag).map(tag => (
										<option value={tag.id} key={tag.id}>{tag.name}</option>
									))}
								</>}
							/>
							<Button
								variant='dark'
								disabled={selectedTag === 'all'}
								onClick={() => {
									addMarketTagHandler(selectedTag);
									setSelectedTag('all');
								}}
							>Добавить тег</Button>
						</div>
					</div>
				</div>
				<div className="grid2x grid2xCustom">
					<div className="block">
						<h5 className="marginBottom20"><b>Последние утвержденные данные</b></h5>
						<div className="grid2x marginBottom20">
							<p>Название</p>
							<p><b>{market.name || 'Название отсутствует'}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Описание</p>
							<p><b>{market.description || 'Описание отсутствует'}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Логотип</p>
							{market.logoUrl
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.logoUrl}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
						<div className="grid2x marginBottom20">
							<p>Фон каталоги</p>
							{market.catalogBackgroundUrl
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.catalogBackgroundUrl}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
						<div className="grid2x">
							<p>Главное изображение</p>
							{market?.imageUrl?.full
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.imageUrl?.preview}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
					</div>
					<div className="block">
						<h5 className="marginBottom20"><b>Недавно внесенные данные</b></h5>
						<div className="grid2x marginBottom20">
							<p>Название</p>
							<p><b>{market?.unverifiedChanges?.name || "Осталось без изменений"}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Описание</p>
							<p><b>{market?.unverifiedChanges?.description || "Осталось без изменений"}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Логотип</p>
							{market.unverifiedLogoUrl
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedLogoUrl}`} alt=""/>)
								: (<p><b>Остался без изменений</b></p>)}
						</div>
						<div className="grid2x marginBottom20">
							<p>Фон каталоги</p>
							{market.unverifiedCatalogBackgroundUrl
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedCatalogBackgroundUrl}`} alt=""/>)
								: (<p><b>Остался без изменений</b></p>)}
						</div>
						<div className="grid2x">
							<p>Главное изображение</p>
							{market?.unverifiedImageUrl
								? (<img className="Images" src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedImageUrl}`} alt=""/>)
								: (<p><b>Осталось без изменений</b></p>)}
						</div>
					</div>
				</div>
			</div>
			{market.status === 'verification'
				? (
					<div className="grid2x marginTop20">
						<Button
							variant="danger"
							onClick={() => rejectHandler()}
							className="marginBottom20"
						>Отклонить изменения</Button>
						<Button
							variant="success"
							onClick={() => approveHandler()}
							className="marginBottom20"
						>Утвердить изменения</Button>
					</div>
				)
				: (
					<div className="marginTop20 flex-center">
						<Button
							variant="danger"
							onClick={() => unpublishHandler()}
							className="marginBottom20"
						>Снять бизнес с публикации</Button>
					</div>
				)
			}
		</>
	)
};

export default About;