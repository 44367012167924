import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Tab, Tabs, Modal, Button, Spinner} from "react-bootstrap";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {getMarketplace} from '../../../api/marketplaceAPI';
import About from './content/aboutContent';
import Products from './content/productsContent';
import {getAllTags} from '../../../api/tagsAPI';
import './marketplacePage.scss';
import MarketplaceTagModel from "../../../models/marketplaceTagModel";
import {getProductsForMarketplace} from "../../../api/productAPI";

const MarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [tags, setTags] = useState([]);
	const [market, setMarket] = useState(null);
	const [rating, setRating] = useState({
		score: 0,
		votes: 0
	});
	const [products, setProducts] = useState([]);
	const [isOpenProduct, setIsOpenProduct] = useState(false);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [key, setKey] = useState('info');

	const getCurrentMarketplace = async () => {
		await getMarketplace(marketplaceId)
		.then(res => {
			setMarket(res);
			setRating(
				{
					score: Number(res.rating?.score) || 0,
					votes: Number(res.rating?.votes) || 0
				});
		})
	};

	const getCurrentMarketplaceProducts = async () => {
		await getProductsForMarketplace(marketplaceId)
		.then(res => {
			setProducts(res);
		})
	};

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res));
	};

	useEffect(() => {
		getCurrentMarketplace();
		getCurrentMarketplaceProducts();
		getTags();
	}, []);

	const approveHandler = async () => {
		market.approveChanges = true;
		await market.save()
			.then(() => {
				navigate('/admin/marketplaces');
			})
		.catch(err => {console.log(err)})
	};

	const rejectHandler = async () => {
		market.rejectChanges = true;
		await market.save()
		.then(() => {
			navigate('/admin/marketplaces');
		})
		.catch(err => {console.log(err)})
	};

	const unpublishedHandler = async () => {
		market.unpublish = true;
		await market.save()
		.then(() => {
			navigate('/admin/marketplaces');
		})
		.catch(err => {console.log(err)})
	};

	const handleClose = () => {
		setSelectedProduct(null);
		setIsOpenProduct(false);
	};

	const approveProductHandler = async () => {
		selectedProduct.approveChanges = true;
		await selectedProduct.save();
		handleClose();
		await getCurrentMarketplace();
	};
	const rejectProductHandler = async () => {
		selectedProduct.rejectChanges = true;
		await selectedProduct.save();
		handleClose();
		await getCurrentMarketplace();
	};
	const unpublishedProductHandler = async () => {
		selectedProduct.unpublish = true;
		await selectedProduct.save();
		handleClose();
		await getCurrentMarketplace();
	};

	const deleteMarketTagHandler = async (tag) => {
	  await tag.destroy()
		.then(() => {
			getCurrentMarketplace();
		})
	}

	const saveLegalDataHandler = async (data) => {
		market.legalData = data;

		await market.save()
		.then(() => {
			getCurrentMarketplace()
		})
	}

	const addMarketTagHandler = async (tagId) => {
		const tag = tags.find(t => t.id === tagId);

		const newMarketTag = new MarketplaceTagModel();
		newMarketTag.tag = tag;
		newMarketTag.marketplace = market;

		await newMarketTag.save({ with: ['marketplace.id', 'tag.id']})
		.then(() => {
			getCurrentMarketplace();
		})
	}

	const switchTypeHandler = async (tag) => {
		const marketRootTags = market?.marketplaceTags.filter(t => t.tag.rootTag);
		const currenttag = marketRootTags.find(t => t.tag.id === tag.id);

		if (currenttag) {
			await currenttag.destroy()
			.then(() => {
				getCurrentMarketplace();
			})
		} else {
			const newMarketTag = new MarketplaceTagModel();
			newMarketTag.tag = tag;
			newMarketTag.marketplace = market;

			await newMarketTag.save({ with: ['marketplace.id', 'tag.id']})
			.then(() => {
				getCurrentMarketplace();
			})
		}
	};

	const submitRating = async () => {
		market.rating = {
			score: Number(rating.score),
		};

		await market.save()
		.then(() => {
			getCurrentMarketplace();
		})
	};

	console.log(selectedProduct);

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Modal size="lg" show={isOpenProduct} onHide={handleClose}>
						<Modal.Header closeButton>
							<Modal.Title>Карточка товара</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							{(selectedProduct?.unverifiedImageUrl || selectedProduct?.imageUrl) && (
								<img
									style={{ height: '400px', margin: '0 auto 20px', display: 'block'}}
									src={`${window.__CONFIG__.REACT_APP_BASE_URL}${selectedProduct?.unverifiedImageUrl || selectedProduct?.imageUrl}`}
									alt=""/>
							)}
							<p>Название - <b>{selectedProduct?.unverifiedChanges?.name || selectedProduct?.name}</b></p>
							<p>Описание - <b>{selectedProduct?.unverifiedChanges?.description || selectedProduct?.description}</b></p>
							<p>Состав - <b>{selectedProduct?.unverifiedChanges?.composition || selectedProduct?.composition}</b></p>
							<div className='grid3x'>
								<p>Категория - <b>{selectedProduct?.category?.name}</b></p>
								<p>Цена - <b>{selectedProduct?.price / 100}</b></p>
								<p>Цена со скидкой - <b>{selectedProduct?.discountPrice ? selectedProduct?.discountPrice / 100 : 'не указана'}</b></p>
							</div>

						</Modal.Body>
						{selectedProduct?.status === 'published'
							? (
								<div className="flex-center" style={{ padding: '12px'}}>
									<Button variant="danger" onClick={() => unpublishedProductHandler()}>
										Снять товар с публикации
									</Button>
								</div>
							)
							: (
								<div className="grid2x" style={{ padding: '12px'}}>
									<Button variant="success" onClick={() => approveProductHandler()}>
										Опубликовать товар
									</Button>
									<Button variant="danger" onClick={() => rejectProductHandler()}>
										Отказать в публикации
									</Button>
								</div>
							)
						}
					</Modal>
					<Button
						variant="dark"
						onClick={() => {navigate(`/admin/marketplaces`)}}
						className="marginBottom20"
					>Назад</Button>
					{market ? (
						<Tabs
							activeKey={key}
							onSelect={(k) => {
								setKey(k)
							}}
							className="marginTop20"
						>
							<Tab eventKey="info" title="Информация о бизнесе">
								<About
									tags={tags}
									market={market}
									rating={rating}
									changeRatingHandler={(v) => setRating(p => ({
										score: v,
										votes: p.votes
									}))}
									submitRating={() => submitRating()}
									rejectHandler={() => rejectHandler()}
									approveHandler={() => approveHandler()}
									unpublishHandler={() => unpublishedHandler()}
									deleteMarketTagHandler={(t) => deleteMarketTagHandler(t)}
									addMarketTagHandler={(id) => addMarketTagHandler(id)}
									switchTypeHandler={(tagForDelete, tagForAdd) => switchTypeHandler(tagForDelete, tagForAdd)}
									saveLegalDataHandler={(data) => saveLegalDataHandler(data)}
								/>
							</Tab>
							<Tab eventKey="products" title="Товары">
								<Products
									products={products}
									selectProductHandler={(p) => {
										setIsOpenProduct(true);
										setSelectedProduct(p)
									}}
								/>
							</Tab>
						</Tabs>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)}
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default MarketplacePage;