import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, InputGroup} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/pageWrappers/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getMarketplace} from "../../../api/marketplaceAPI";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import {getLocationsForMarketplace} from "../../../api/marketplaceLocationsAPI";
import AddLocation from "../../../components/addLocation/addLocation";
import MarketplaceLocationsModel from "../../../models/marketplaceLocationsModel";
import {getAllTags} from "../../../api/tagsAPI";
import MarketplaceTagModel from "../../../models/marketplaceTagModel";
import {getCurrentSCModel} from "../../../api/serviceConfigAPI";
import GlobalLoader from "../../../components/globalLoader/globalLoader";

const EditMarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [globalLoading, setGlobalLoading] = useState(false);
	const [serviceConfig, setServiceConfig] = useState(null);
	const [marketplace, setMarketplace] = useState(null);
	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState('all');
	const [formData, setFormData] = useState({
		name: '',
		description: '',

		organization_info: '',
		bank_info: '',
		email: '',
		phone: '',
	});
	const [errors, setErrors] = useState({
		name: null,
		description: null,
		email: null
	});
	const [logo, setLogo] = useState('');
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');
	const [newLocations, setNewLocations] = useState([]);
	const [marketLocations, setMarketLocations] = useState([]);

	const getCurrentConfig = async () => {
		await getCurrentSCModel()
		.then(res => {
			setServiceConfig(res)
			// setIsEnable(res?.freeDeliveryPromo.enabled);
		});
	};

	const getLocations = async () => {
		await getLocationsForMarketplace(marketplaceId)
		.then(res => {
			setMarketLocations(res)
		})
	}

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(t => !t.rootTag)));
	};

	const getCurrentMarketplace = async () => {
		await getMarketplace(marketplaceId)
		.then(res => {
			setMarketplace(res);
			if (res?.status === 'draft') {
				setFormData({
					name: res?.unverifiedChanges?.name || res?.name,
					description: res?.unverifiedChanges?.description || res?.description,
					organization_info: res?.legalData.organization_info,
					bank_info: res?.legalData.bank_info,
					email: res?.legalData.email,
					phone: res?.legalData.phone,
				})
			} else {
				setFormData({
					name: res?.name || res?.unverifiedChanges?.name,
					description: res?.description || res?.unverifiedChanges?.description,
					organization_info: res?.legalData.organization_info,
					bank_info: res?.legalData.bank_info,
					email: res?.legalData.email,
					phone: res?.legalData.phone,
				})
			}

			setLogo(res.logoUrl || res.unverifiedLogoUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedLogoUrl || res.logoUrl}` : '');
			setBackground(res.catalogBackgroundUrl || res.unverifiedCatalogBackgroundUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedCatalogBackgroundUrl || res.catalogBackgroundUrl}` : '');
			setMainImage(res.unverifiedImageUrl || res.imageUrl.preview ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedImageUrl || res.imageUrl.preview}` : '');
		})
	};

	useEffect(() => {
		getLocations();
		getTags();
		getCurrentMarketplace();
		getCurrentConfig();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		const EMAIL_REGEXP = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

		if (!formData.name || !formData.description || (formData.email && !EMAIL_REGEXP.test(formData.email))) {
			setErrors({
				name: !formData.name ? 'Название не заполнено' : null,
				description: !formData.description ? 'Описание не заполнено' : null,
				email: formData.email ? !EMAIL_REGEXP.test(formData.email) ? 'Некорректный email': null : null
			});
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		} else {
			setGlobalLoading(true);
			if (marketplace.status === 'draft' || marketplace.status === 'verification') {
				if (marketplace.unverifiedChanges.name !== formData.name || marketplace.unverifiedChanges.description !== formData.description) {
					marketplace.unverifiedChanges = {
						name: formData.name,
						description: formData.description
					};
				}
			} else {
				if (marketplace.name !== formData.name || marketplace.description !== formData.description) {
					marketplace.unverifiedChanges = {
						name: formData.name,
						description: formData.description
					};
				}
			}

			if (
				marketplace.legalData.organization_info !== formData.organization_info ||
				marketplace.legalData.bank_info !== formData.bank_info ||
				marketplace.legalData.email !== formData.email ||
				marketplace.legalData.phone !== formData.phone
			) {
				marketplace.legalData = {
					organization_info: formData.organization_info,
					bank_info: formData.bank_info,
					email: formData.email,
					phone: formData.phone,
				}
			}

			if (logo?.file) {
				marketplace.unverifiedLogo = logo && (await uploadHandler(logo));
			}

			if (background?.file) {
				marketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
			}

			if (mainImage?.file) {
				marketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
			}

			await marketplace.save()
			.then(async () => {
				setGlobalLoading(false);
				window.location.pathname = `/merchant/${marketplaceId}/marketplace`;
			})
		}
	};

	const removeMarketLocationHandler = async (l) => {
		await l.destroy()
		.then(() => {
			getLocations();
		})
	}

	const deleteMarketTagHandler = async (tag) => {
		await tag.destroy()
		.then(() => {
			getCurrentMarketplace();
		})
	}
	const addMarketTagHandler = async (tagId) => {
		const tag = tags.find(t => t.id === tagId);

		const newMarketTag = new MarketplaceTagModel();
		newMarketTag.tag = tag;
		newMarketTag.marketplace = marketplace;

		await newMarketTag.save({ with: ['marketplace.id', 'tag.id']})
		.then(() => {
			getCurrentMarketplace();
		})
	}

	const saveLocationHandler = async (loc) => {
		const currentLocation = new MarketplaceLocationsModel();
		currentLocation.name = loc.name;
		currentLocation.address = loc.address;
		currentLocation.availableForSelfPickup = loc.available_for_self_pickup;
		currentLocation.lat = loc.lat;
		currentLocation.lng = loc.lng;
		currentLocation.marketplace = marketplace;

		await currentLocation.save({ with: "marketplace.id"})
		.then(() => {
			getLocations();
		})
	};

	const freeDeliveryHandler = async (e) => {
		marketplace.promoOptions = {
			free_delivery_on_order_amount: e.target.checked
		}
		await marketplace.save()
		.then(() => {
			getCurrentMarketplace();
			getCurrentConfig();
		})
	}

	const bonusSwitchHandler = async () => {
		marketplace.allowBonusPointsPayment = !marketplace.allowBonusPointsPayment;

		await marketplace.save()
		.then(() => {
			getCurrentMarketplace();
			getCurrentConfig();
		})
	}

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{globalLoading && <GlobalLoader/>}
				{marketplace
					? (
						<>
							<div>
								<Button
									variant="dark"
									onClick={() => {navigate(`/merchant/${marketplaceId}/marketplace`)}}
									className="marginBottom20"
								>Назад</Button>
								<h1 className="marginBottom20">Редактирование</h1>
								<div className="block marginBottom10">
									<p>{`Город площадки: ${marketplace?.city?.name}`}</p>
								</div>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">Название</InputGroup.Text>
									<Form.Control
										placeholder="Название"
										aria-label="Название"
										aria-describedby="basic-addon1"
										value={formData.name}
										isInvalid={errors.name}
										onChange={e => changeHandler('name', e.target.value)}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.name}
									</Form.Control.Feedback>
								</InputGroup>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">Описание</InputGroup.Text>
									<Form.Control
										placeholder="Описание"
										aria-label="Описание"
										aria-describedby="basic-addon1"
										value={formData.description}
										isInvalid={errors.description}
										onChange={e => changeHandler('description', e.target.value)}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.description}
									</Form.Control.Feedback>
								</InputGroup>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon2">Реквизиты организации</InputGroup.Text>
									<Form.Control
										placeholder="ИНН, КПП и далее по форме"
										aria-label="Реквизиты организации"
										aria-describedby="basic-addon2"
										value={formData.organization_info}
										onChange={e => changeHandler('organization_info', e.target.value)}
									/>
								</InputGroup>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon2">Банковские реквизиты организации</InputGroup.Text>
									<Form.Control
										placeholder="Введите банковские реквизиты организации"
										aria-label="Банковские реквизиты организации"
										aria-describedby="basic-addon2"
										value={formData.bank_info}
										onChange={e => changeHandler('bank_info', e.target.value)}
									/>
								</InputGroup>
								<div className="grid2x ">
									<InputGroup className="mb-3">
										<InputGroup.Text id="basic-addon2">Email для связи</InputGroup.Text>
										<Form.Control
											placeholder="Введите email для связи"
											aria-label="Email для связи"
											aria-describedby="basic-addon2"
											value={formData.email}
											isInvalid={errors.email}
											onChange={e => changeHandler('email', e.target.value)}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.email}
										</Form.Control.Feedback>
									</InputGroup>
									<InputGroup className="mb-3">
										<InputGroup.Text id="basic-addon2">Телефон для связи</InputGroup.Text>
										<Form.Control
											placeholder="Введите телефон для связи"
											aria-label="Телефон для связи"
											aria-describedby="basic-addon2"
											value={formData.phone}
											onChange={e => {
												if (e.target.value.length <= 11) {
													changeHandler('phone', e.target.value.replace(/\D/, ''))
												}
											}}
										/>
									</InputGroup>
								</div>

								<div className="block marginBottom10">
									<h5 className="marginBottom20"><b>Категория бизнеса</b></h5>
									<div className='grid2x'>
										<div className="flex flex-wrap">
											{marketplace?.marketplaceTags.filter(mt => !mt.tag.rootTag)?.length === 0 ? (
												<p>У бизнеса нет категорий</p>
											) : (
												marketplace?.marketplaceTags.filter(mt => !mt.tag.rootTag).map(t => (
													<div className="ActiveTag">
														<b>{t.tag.name}</b>
														<Button variant="outline-danger" onClick={() => deleteMarketTagHandler(t)}>Удалить</Button>
													</div>
												))
											)}
										</div>
										<div className='flex flex-wrap'>
											<InputGroup>
												<InputGroup.Text>Категория</InputGroup.Text>
												<Form.Select
													aria-label="Выберите роль"
													aria-placeholder="Выберите роль"
													onChange={e => setSelectedTag(e.target.value)}
													value={selectedTag}
												>
													<option value={'all'} disabled>Выберите категорию</option>
													{tags.map(tag => (
														<option value={tag.id} key={tag.id}>{tag.name}</option>
													))}
												</Form.Select>
											</InputGroup>
											<Button
												style={{ width: '100%'}}
												variant='dark'
												disabled={selectedTag === 'all'}
												onClick={() => {
													addMarketTagHandler(selectedTag);
													setSelectedTag('all');
												}}
											>Добавить категорию</Button>
										</div>
									</div>
								</div>
								<div className="block marginBottom10">
									<h5 className="marginBottom20"><b>Бесплатная доставка</b></h5>
									{serviceConfig?.freeDeliveryPromo?.enabled
										? (
											<p className="marginBottom20">
												В данный момент на платформе <b>включена</b> бесплатная доставка при заказе от
												{` ${serviceConfig?.freeDeliveryPromo?.from_order_amount / 100} Р`}</p>
										)
										: (<p>В данный момент бесплатная доставка на платформе <b>отключена</b></p>)
									}
									{serviceConfig?.freeDeliveryPromo?.enabled && (
										<Form.Check
											type="switch"
											id="custom-switch"
											label={`${marketplace?.promoOptions?.free_delivery_on_order_amount ? 'Отключить' : 'Включить'} бесплатную доставку в магазине на сумму от ${serviceConfig?.freeDeliveryPromo?.from_order_amount / 100} Р`}
											checked={marketplace?.promoOptions?.free_delivery_on_order_amount}
											onChange={(e) => freeDeliveryHandler(e)}
										/>
									)}
								</div>
								<div className="block marginBottom10">
									<h5 className="marginBottom20"><b>Бонусные баллы</b></h5>
									<Form.Check
										type="switch"
										id="custom-switch"
										label={`Поддержка оплаты бонусными баллами`}
										checked={marketplace?.allowBonusPointsPayment}
										onChange={() => bonusSwitchHandler()}
									/>
								</div>
								<DropZoneUploader
									preview={logo}
									acceptPreviewHandler={(f) => {
										if (f) attachLogoHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите логотип вашего бизнеса в формате jpeg или png"
								/>
								<DropZoneUploader
									preview={background}
									acceptPreviewHandler={(f) => {
										if (f) attachBackgroundHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите фон для вашего каталога в формате jpeg или png"
								/>
								<DropZoneUploader
									preview={mainImage}
									acceptPreviewHandler={(f) => {
										if (f) attachMainHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите ваше основное изображение в формате jpeg или png"
								/>
								<AddLocation
									mode="edit"
									locations={newLocations}
									setNewLocation={(newLocation) => setNewLocations(p => ([...p, newLocation]))}
									removeLocationHandler={(id) => {
										setNewLocations(newLocations.filter(i => i.arrayId !== id))
									}}
									existingLocations={marketLocations}
									addLocationHandlerInEditMode={(loc) => saveLocationHandler(loc)}
									removeExistingMarketLocationHandler={(l) => removeMarketLocationHandler(l)}
								/>
							</div>
							<Button
								variant="dark"
								onClick={() => {
									submitHandler();
								}}
								style={{width: '100%'}}
								className="marginBottom20"
								// disabled={!formData.name || !formData.description}
							>Сохранить</Button>
						</>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default EditMarketplacePage