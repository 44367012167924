import {useEffect, useState} from 'react';
import {getCurrentSCModel} from '../../../api/serviceConfigAPI';
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {Button, Form, ButtonGroup} from "react-bootstrap";
import {getAllTags} from "../../../api/tagsAPI";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import GlobalLoader from "../../../components/globalLoader/globalLoader";
import Input from "../../../components/controllElements/input";

const SharesPage = () => {
	const [globalLoading, setGlobalLoading] = useState(false);
	const [serviceConfig, setServiceConfig] = useState(null);
	const [tags, setTags] = useState([]);
	const [isEnable, setIsEnable] = useState(null);
	const [currentTag, setCurrentTag] = useState(null);
	const [amount, setAmount] = useState(0);
	const [serviceFee, setServiceFee] = useState(0);
	const [factor, setFactor] = useState(0);
	const [editState, setEditState] = useState({
		name: '',
		image: ''
	});
	const [validity_period, setValidity_period] = useState('');

	const getTags = async () => {
		await getAllTags()
		.then(res => {
			const rootTags = res.filter(t => (t.rootTag && t.name !== 'Магазины' && t.name !== 'Рестораны'));
			setTags(rootTags);
			setCurrentTag(rootTags[0]);
			setEditState({
				name: rootTags[0].name,
				image: `${window?.__CONFIG__?.REACT_APP_BASE_URL}${rootTags[0].imageUrl}`
			})
		})
	};

	const getCurrentConfig = async () => {
		await getCurrentSCModel()
		.then(res => {
			setServiceConfig(res)
			setAmount(Number(res?.freeDeliveryPromo.from_order_amount) / 100);
			setIsEnable(res?.freeDeliveryPromo.enabled);
			setFactor(Number(res?.bonusPoints?.factor) * 100);
			setServiceFee(Number(res?.serviceFee) / 100);
			setValidity_period(res?.bonusPoints?.validity_period);
		});
	};

	useEffect(() => {
		getCurrentConfig();
		getTags();
	}, []);

	const updateConfigFreeDelivery = async () => {
	  serviceConfig.freeDeliveryPromo = {
			enabled: isEnable,
			from_order_amount: amount * 100
		}
		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	const updateConfigBonus = async () => {
		serviceConfig.bonusPoints = {
			factor: factor / 100,
			validity_period: Number(validity_period)
		}
		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	const updateServiceFee = async () => {
		serviceConfig.serviceFee = Number(serviceFee * 100);

		await serviceConfig.save()
		.then(() => {
			getCurrentConfig();
		})
	};

	const attachAvatarHandler = (file) => {
		attachHandler(file, (f, c) => changeHandler('image', { file: f, checkSum: c }));
	};

	const changeHandler = (field, value) => {
		setEditState(p => ({...p, [field]: value}));
	};

	const updateTag = async () => {
		currentTag.name = editState.name;
		if (editState.image?.file) {
			currentTag.image = editState.image && (await uploadHandler(editState.image));
		}

		await currentTag.save()
		.then(() => {
			setGlobalLoading(false);
			getTags();
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{globalLoading && <GlobalLoader/>}
				<div className="block marginBottom20">
					<h3 className="marginBottom20">Бесплатная доставка</h3>
					<Form.Check
						type="switch"
						id="custom-switch"
						label="Включить бесплатную доставку на платформе"
						checked={isEnable}
						onChange={() => setIsEnable(p => !p)}
					/>
					<p className="alternative_label marginBottom10">Сумма с которой доставка будет бесплатной (в рублях)</p>
					<div className="flex marginTop20">
						<Input
							label="Сумма с которой доставка будет бесплатной (в рублях)"
							placeholder="Введите сумму"
							value={amount}
							disabled={!isEnable}
							type="number"
							onChange={e => setAmount(e.target.value)}
						/>
						{(
							serviceConfig?.freeDeliveryPromo?.enabled !== isEnable ||
							(Number(serviceConfig?.freeDeliveryPromo?.from_order_amount / 100)) !== (Number(amount))
						) && (
							<Button
								style={{width: '300px'}}
								variant="dark"
								onClick={() => updateConfigFreeDelivery()}
							>
								Сохранить
							</Button>
						)}
					</div>
				</div>

				<div className="block marginBottom20">
					<h3 className="marginBottom20">Бонусные баллы </h3>

					<div className="flex-column marginTop20">
						<p className="alternative_label marginBottom10">Доля от суммы заказа, которая будет преобразована в бонусные баллы для покупателя</p>
						<Input
							className="marginBottom10"
							label="Доля от суммы заказа, которая будет преобразована в бонусные баллы для покупателя"
							placeholder="Доля от суммы заказа"
							value={factor}
							type="number"
							onChange={e => setFactor(e.target.value)}
						/>

						<p className="alternative_label marginBottom10">Cрок действия бонусных баллов в днях (Дата применется только к новым баллам)</p>
						<Input
							className="marginBottom10"
							label="Cрок действия бонусных баллов в днях (Дата применется только к новым баллам)"
							placeholder="Введите срок, в днях"
							value={validity_period}
							type="number"
							onChange={e => setValidity_period(e.target.value)}
						/>

						<Button
							style={{width: '300px'}}
							variant="dark"
							onClick={() => updateConfigBonus()}
						>
							Сохранить
						</Button>
					</div>
				</div>

				<div className="block marginBottom20">
					<h3 className="marginBottom20">Сервисный сбор</h3>

					<p className="alternative_label marginBottom10">Сервисный сбор в рублях</p>
					<Input
						className="marginBottom10"
						label="Сервисный сбор в рублях"
						placeholder="Введите сумму"
						value={serviceFee}
						onChange={e => setServiceFee(e.target.value)}
					/>

					<Button
						style={{width: '300px'}}
						variant="dark"
						disabled={!serviceFee}
						onClick={() => updateServiceFee()}
					>
						Сохранить
					</Button>
				</div>

				<div className="block">
					<h3 className="marginBottom20">Редактирование плашек</h3>
					<ButtonGroup className="marginBottom10" >
						{tags.map(t => (
							<Button
								variant={editState.name === t.name ? "dark" : "outline-dark"}
								key={t.name}
								onClick={() => {
									setEditState({
										name: t.name,
										image: `${window?.__CONFIG__?.REACT_APP_BASE_URL}${t.imageUrl}`
									});
									setCurrentTag(t);
								}}
							>
								{t.name}
							</Button>
						))}
					</ButtonGroup>
					<div className="block">
						<Input
							className="marginBottom10"
							label="Название"
							placeholder="Название"
							onChange={e => changeHandler('name', e.target.value)}
							value={editState.name}
						/>
						<DropZoneUploader
							preview={editState.image}
							acceptPreviewHandler={(f) => {
								if (f) attachAvatarHandler(f[0]);
							}}
							className="marginBottom10"
							text="Прикрепите картинку в формате jpeg или png"
						/>
						<Button
							variant="dark"
							style={{ width: '100%' }}
							disabled={!editState.name || !editState.image}
							onClick={() => {
								setGlobalLoading(true);
								updateTag();
							}}
						>Обновить</Button>
					</div>
				</div>

			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default SharesPage