import {Button} from "react-bootstrap";
import DropZoneUploader from "../../../../components/dropZoneUploader/dropZoneUploader";
import Input from "../../../../components/controllElements/input";
import Select from "../../../../components/controllElements/select";

const CreateBlock = ({
	editState,
	changeHandler,
	errors,
	markets,
	attachAvatarHandler,
	submitHandler,
	closeHandler
}) => {
	return (
		<div className="block marginBottom20">
			<div className="flex-space-between marginBottom20">
				<h4>{editState.mode === 'create' ? 'Создание баннера' : `Редактирование баннера - ${editState.data.name}`}</h4>
				<Button variant="dark" onClick={() => closeHandler()}>Закрыть</Button>
			</div>
			<div className="grid3x grid3xTo1Tablet marginBottom10">
				<Input
					label="Название"
					onChange={e => changeHandler('name', e.target.value)}
					value={editState.data.name}
					withValidation
					error={errors.name}
					errorText={errors?.name}
				/>
				<Select
					label="Тип баннера"
					placeholder="Выберите тип"
					onChange={e => changeHandler('bannerType', e.target.value)}
					value={editState.data.bannerType}
					customOptions={<>
						<option value='regular'>Обычный</option>
						<option value='big'>Большой</option>
					</>}
				/>
				<Input
					label="Позиция"
					onChange={e => changeHandler('position', e.target.value)}
					value={editState.data.position}
					withValidation
					type="number"
					error={errors.position}
					errorText={errors?.position}
				/>
			</div>
			<div className="grid2x marginBottom10">
				<Select
					label="Маркетплейс"
					placeholder="Выберите маркетплейс"
					onChange={e => changeHandler('marketplaceId', e.target.value)}
					value={editState.data.marketplaceId}
					withValidation
					error={errors.marketplaceId}
					errorText={errors?.marketplaceId}
					customOptions={<>
						<option value='all' disabled>Выберите маркетплейс</option>
						{markets.map(m => (<option key={m.id} value={m.id}>{m.name}</option>))}
					</>}
				/>
				<Select
					label="Тип ссылки"
					placeholder="Выберите тип"
					onChange={e => changeHandler('linkType', e.target.value)}
					value={editState.data.linkType}
					customOptions={<>
						<option value='external_link'>Ссылка</option>
						<option value='marketplace'>Маркетплейс</option>
					</>}
				/>
			</div>
			{editState.data.linkType === 'external_link' && (
				<Input
					label="Ссылка баннера"
					onChange={e => changeHandler('externalLink', e.target.value)}
					value={editState.data.externalLink}
					withValidation
					error={errors.externalLink}
					errorText={errors?.externalLink}
				/>
			)}
			<DropZoneUploader
				preview={editState.data.image}
				acceptPreviewHandler={(f) => {
					if (f) attachAvatarHandler(f[0]);
				}}
				error={errors.image}
				className="marginBottom10"
				text="Прикрепите баннер в формате jpeg или png"
			/>
			<Button variant="dark" style={{width: '100%'}} onClick={() => submitHandler()}>Сохранить</Button>
		</div>
	)
};

export default CreateBlock;