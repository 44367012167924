import {useEffect, useState} from "react";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import ProtectedWrapper from "../../../components/pageWrappers/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/pageWrappers/adminPageWrapper/adminPageWrapper";
import './tagsPage.scss';
import {getAllTags} from "../../../api/tagsAPI";
import {addTagToLayout} from "../../../api/serviceConfigAPI";
import {Button, Modal, Spinner} from "react-bootstrap";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import TagModel from "../../../models/tagModel";
import TagCard from "../../../components/cards/tagCard/tagCard";
import GlobalLoader from "../../../components/globalLoader/globalLoader";
import Input from "../../../components/controllElements/input";
import Select from "../../../components/controllElements/select";

const TagsPage = () => {
	const [globalLoading, setGlobalLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [currentTag, setCurrentTag] = useState({tag: null, mode: 'create'});
	const [currentTagPreview, setPreview] = useState('');
	const [tags, setTags] = useState([]);
	const [type, setType] = useState('all');
	const [itemOnDelete, setDeleteItem] = useState(null);
	const [tagName, setTagName] = useState('');

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(i => !i.rootTag)));
	};

	const deleteHandler = async (t) => {
		await t.destroy()
		.then(() => getTags());
	}

	useEffect(() => {
		getTags();
	},[]);

	const closeHandler = () => {
		setOpen(false);
		setPreview('');
		setTagName('');
	};

	const onClickHandler = (tag) => {
		setCurrentTag({tag, mode: 'edit'});
		setTagName(tag.name);
		setPreview(`${window.__CONFIG__.REACT_APP_BASE_URL}${tag.imageUrl}`);
		setOpen(true);
	};

	const onClickCreateHandler = () => {
		setCurrentTag({tag: null, mode: 'create'});
		setPreview(``);
		setOpen(true);
	};

	const attachPreviewHandler = (file) => {
		attachHandler(file, (f, c) => setPreview({ file: f, checkSum: c }));
	};

	const updateHandler = async () => {
		if (currentTagPreview?.file) {
			currentTag.tag.image = currentTagPreview && (await uploadHandler(currentTagPreview));
		}

		currentTag.tag.name = tagName;

		await currentTag.tag.save()
		.then(() => {
			setGlobalLoading(false);
			setCurrentTag(null);
			setPreview('');
			setTagName('');
			setOpen(false);
		})
	}

	const createHandler = async () => {
		const newtag = new TagModel();
		if (currentTagPreview?.file) {
			newtag.image = currentTagPreview && (await uploadHandler(currentTagPreview));
		}
		newtag.name = tagName;

		await newtag.save()
		.then(() => {
			if (currentTag.mode === 'create') {
				addTagToLayout(
					newtag.id,
					type,
					() => {
						setCurrentTag(null);
						setPreview('');
						setTagName('');
						setOpen(false);
						setType('all');
					}
				)
			} else {
				setCurrentTag(null);
				setPreview('');
				setTagName('');
				setOpen(false);
				setType('all');
			}
		})
		setGlobalLoading(false);
		getTags();
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{globalLoading && <GlobalLoader/>}
				<Modal show={isOpen} onHide={() => closeHandler()}>
					<Modal.Header closeButton>
						<Modal.Title>{currentTag?.mode === 'edit' ? 'Редактирование' : 'Создание'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="flex-column marginBottom20">
							<Input
								className="marginBottom20"
								label="Тег"
								placeholder="Введите имя тега"
								onChange={e => setTagName(e.target.value)}
								value={tagName}
							/>
							{currentTag?.mode === 'create' && (
								<Select
									label="Разместить в"
									onChange={e => setType(e.target.value)}
									value={type}
									customOptions={<>
										<option value="all" key="all" disabled>Выберите значение</option>
										<option value="store" key="all" >Магазинах</option>
										<option value="restaurant" key="all" >Ресторанах</option>
									</>}
								/>
							)}
						</div>
						<DropZoneUploader
							preview={currentTagPreview}
							acceptPreviewHandler={(f) => {
								if (f) attachPreviewHandler(f[0]);
							}}
							className="marginBottom20"
							text="Прикрепите изображение тега в формате jpeg или png"
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => closeHandler()}>
							Отмена
						</Button>
						<Button variant="success" onClick={() => {
							setGlobalLoading(true);
							if (currentTag.mode === 'edit') {
								updateHandler();
							} else {
								createHandler();
							}
						}}>
							Сохранить
						</Button>
					</Modal.Footer>
				</Modal>
				<h3 className="marginBottom20">Cправочник тегов</h3>
				{tags.length > 0
					? (
						<div className='grid5x'>
							<Button
								variant="dark"
								onClick={() => onClickCreateHandler()}
							>
								Добавить тег
							</Button>
							{tags.map(t => (
								<TagCard
									key={t.id}
									tag={t}
									itemOnDelete={itemOnDelete}
									beforeDeleteHandler={(tag) => setDeleteItem(tag)}
									deleteHandler={(tag) => deleteHandler(tag)}
									cancelHandler={() => setDeleteItem(null)}
									onClickHandler={(tag) => onClickHandler(tag)}
								/>
							))}
						</div>
					)
					: (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default TagsPage;