import MessageItem from "../messageItem/messageItem";
import {Button} from "react-bootstrap";
import {getAdminChat} from '../../../api/adminChatsAPI';
import './activeChat.scss';
import {useEffect, useState} from "react";
import ChatMessageModel from "../../../models/chatMessageModel";
import Input from "../../controllElements/input";

const ActiveChat = ({
	activeChatId,
	selectedChat,
	cable
}) => {
	let chatConnection;
	const [messages, setMessages] = useState([]);
	const [currentMessageText, setCurrentMessageText] = useState('');

	const getHistory = async () => {
		await getAdminChat(activeChatId)
		.then(res => {
			setMessages(res?.chatMessages.reverse());
			scrollHandler();
		})
	};

	const sendMessageHandler = async () => {
		const currentMessage = new ChatMessageModel();
		currentMessage.content = currentMessageText;
		currentMessage.adminUserChatRoom = selectedChat;

		await currentMessage.save({with: 'adminUserChatRoom.id'})
		.then(() => {
			scrollHandler();
			setCurrentMessageText('');
		})
	};

	useEffect(() => {
		if (activeChatId) {
			getHistory();
		}
	}, [activeChatId])

	useEffect(() => {
		if (cable && cable.subscriptions && !chatConnection) {
			chatConnection = cable.subscriptions.create(
				{channel: 'AdminChatChannel', chat_room_id: activeChatId},
				{received: data => {
						getHistory();
						scrollHandler();
				}}
			)
		}
	},[activeChatId]);

	useEffect(() => {
		const listener = (e) => {
			if ((e.key === "Enter") && (currentMessageText.length > 0)) {
				sendMessageHandler();
			}
		}

		document.addEventListener('keypress', listener);

		return (() => {
			document.removeEventListener('keypress', listener);
		})
	})

	const scrollHandler = () => {
		const cnt = document.getElementById('msgs');
		cnt.scrollBy({left: 0, top: 1000000, behavior:"smooth"});
	}

	return (
		<>
			<div className="activeChatRootMessages" id="msgs">
				{messages.map((msg, idx) => (
					<MessageItem
						id={`msg_${msg?.user?.id}_msgidx${idx}`}
						key={`${msg.id}_${msg?.content}`}
						text={msg?.content || ''}
						date={msg?.createdAt ? new Date(msg?.createdAt) : new Date()}
						isYours={msg?.user?.role === 'admin'}
					/>
				))}
			</div>

			<div className="activeChatRootNewMessage flex">
				<Input
					placeholder="Введите текст сообщения"
					value={currentMessageText}
					onChange={e => setCurrentMessageText(e.target.value)}
					customActions={<>
						<Button
							disabled={currentMessageText.length === 0}
							variant="dark"
							onClick={() => sendMessageHandler()}
						>
							Отправить
						</Button>
					</>}
				/>
			</div>
		</>
	)
};

export default ActiveChat
