import {Form, InputGroup} from "react-bootstrap";

const Input = ({
	label,
	onChange,
	value,
	withValidation = false,
	error,
	disabled = false,
	errorText,
	className,
	type,
	style,
	placeholder,
	customActions = null
}) => {
	return (
		<InputGroup className={className} style={style}>
			{label && (
				<InputGroup.Text>{label}</InputGroup.Text>
			)}
			<Form.Control
				aria-label={label}
				onChange={e => onChange(e)}
				value={value}
				isInvalid={!!error}
				type={type}
				placeholder={placeholder}
				disabled={disabled}
			/>
			{customActions && (
				customActions
			)}
			{withValidation && (
				<Form.Control.Feedback type="invalid">
					{errorText}
				</Form.Control.Feedback>
			)}
		</InputGroup>
	)
}

export default Input;
