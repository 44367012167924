import { useState, useRef } from 'react';
import {Button} from "react-bootstrap";
import {
	YMaps,
	Map,
	Placemark,
	SearchControl,
	ZoomControl
} from "@pbe/react-yandex-maps";
import './map.scss';

const MapComponent = ({currentDotOnMap, setCurrentDotOnMap, removeDotHandler, existingLocations, newLocations}) => {
	const [center, setCenter] = useState([47.2313, 39.7233])
	const ymaps = useRef(null);
	const ref = useRef();
	const API_KEY = "67a0be60-9a39-4e20-b515-cf79190d52bc";

	const onClickMapHandler = async (e) => {
		const coords = e.get("coords");

		await ymaps.current.geocode(coords).then((res) => {
			const firstGeoObject = res.geoObjects.get(0);
			const newAddress = [
				firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
				firstGeoObject.getPremiseNumber()
			]
			.filter(Boolean)
			.join(", ");
			setCenter([coords[0], coords[1]]);
			setCurrentDotOnMap({
				lat: coords[0],
				lng: coords[1],
				addressFromMap: newAddress
			})
		})
	};

	const onDragNewDotHandler = async () => {
		const coords = ref.current.geometry._coordinates;
		await ymaps.current.geocode(coords).then((res) => {
			const firstGeoObject = res.geoObjects.get(0);
			const newAddress = [
				firstGeoObject.getThoroughfare() || firstGeoObject.getPremise(),
				firstGeoObject.getPremiseNumber()
			]
			.filter(Boolean)
			.join(", ");
			setCenter([coords[0], coords[1]]);
			setCurrentDotOnMap({
				lat: coords[0],
				lng: coords[1],
				addressFromMap: newAddress
			})
		})
	}

	return (
		<YMaps
			query={{
				load: "package.full",
				apikey: API_KEY,
				suggest_apikey: API_KEY
			}}
		>
			<Map
				width="100%"
				height="330px"
				modules={["control.ZoomControl"]}
				state={{center: center, zoom: 12, controls: ["zoomControl"]}}
				onLoad={(e) => {
					ymaps.current = e;
					setCenter(existingLocations && existingLocations.length > 0 ? [existingLocations[0]?.lat || 47.2313, existingLocations[0]?.lng || 39.7233]:[47.2313,39.7233])
				}}
				onClick={(e) => onClickMapHandler(e)}
			>
				{(currentDotOnMap.lat > 0 || currentDotOnMap.lng > 0) && (
					<Button
						variant="dark"
						className="marginTop20"
						style={{width: '100%'}}
						onClick={() => removeDotHandler()}
					>Удалить точку с карты</Button>
				)}
			 	<ZoomControl options={{ float: "right" }} />
				<SearchControl options={{ float: "right" }} />
				{(currentDotOnMap.lat > 0 || currentDotOnMap.lng > 0) && (
					<Placemark
						instanceRef={ref}
						options={{
							draggable: true,
							preset: "islands#redIcon",
						}}
						onDragEnd={(e) => onDragNewDotHandler(e)}
						geometry={[currentDotOnMap.lat, currentDotOnMap.lng]}
					/>
				)}
				{newLocations?.length > 0 && (
					newLocations.map(loc => (
						<Placemark
							key={`new_${loc.lat},${loc.lng}`}
							geometry={[loc.lat, loc.lng]}
							options={{
								draggable: false,
								preset: "islands#blueDotIcon",
							}}
						/>
					))
				)}
				{existingLocations?.length > 0 && (
					existingLocations.map(loc => (
						<Placemark
							key={`old_${loc.lat},${loc.lng}`}
							geometry={[loc.lat, loc.lng]}
							options={{
								draggable: false,
								preset: "islands#greenDotIcon",
							}}
						/>
					))
				)}
			</Map>
		</YMaps>
	)
};

export default MapComponent;