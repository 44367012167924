import {attr, belongsTo, hasMany, hasOne} from "spraypaint";
import ApplicationRecord from "./baseModel";
import OrderItemModel from "./orderItemModel";
import CouponUsageModel from "./couponUsageModel";
import AddressesModel from "./addressesModel";

const OrderModel = ApplicationRecord.extend({
	static: { jsonapiType: "orders" },
	attrs: {
		id: attr(),
		fromCartId: attr(),
		deliveryType: attr(),
		publicUid: attr(),
		paymentType: attr(),
		comment: attr(),
		status: attr(),
		statusComment: attr(),
		freeDelivery: attr(),
		itemsPrice: attr(),
		deliveryPrice: attr(),
		totalPrice: attr(),
		createdAt: attr({ persist: false }),
		updatedAt: attr({ persist: false }),
		startProcessing: attr(),
		bonusPoints: attr(),
		completeProcessing: attr(),
		giveToReceiver: attr(),
		completeDelivery: attr(),
		cancelByAdmin: attr(),
		cancelByMerchant: attr(),
		cancelByCourier: attr(),
		cancelByUser: attr(),
		userHasExistingCartWithItems: attr(),
		user: belongsTo(),
		marketplace: belongsTo(),
		userAddress: belongsTo(),
		courier: belongsTo(),
		pickupLocation: belongsTo(),
		orderItems: hasMany(OrderItemModel),
		couponUsage: hasOne()
	}
})

export default OrderModel