import {useEffect, useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import Alert from 'react-bootstrap/Alert';
import './notification.scss';
import {orderStatusBuilder} from "../../helpers/constants/statuses";
import {Button} from "react-bootstrap";
import {TOKEN, USER} from "../../helpers/constants/localStorageConstants";

const Notification = ({cable}) => {
	let orderConnection, courierConnection, messagesConnection;
	const currentUser = JSON.parse(localStorage.getItem(USER));
	const [notificationArray, setNotificationArray] = useState([]);

	useEffect(() => {
		if (cable && cable.subscriptions) {
			if (!orderConnection) {
				orderConnection = cable.subscriptions.create(
					{channel: 'OrderStatusNotificationsChannel' },
					{
						connected: () => {console.log('connect to OrderStatusNotificationsChannel')},
						received: data => {setNotificationArray(p => ([...p, {...data, nID: uuidv4(), type: 'order'}]))},
						disconnected: () => {console.log('disconnected to OrderStatusNotificationsChannel')},
					}
				)
			}
			if (!courierConnection && currentUser?.role === 'admin') {
				courierConnection = cable.subscriptions.create(
					{channel: 'CourierAvailabilityStatusChannel' },
					{
						connected: () => {console.log('connect to CourierAvailabilityStatusChannel')},
						received: data => {setNotificationArray(p => ([...p, {...data, nID: uuidv4(), type: 'courier'}]))},
						disconnected: () => {console.log('disconnected to CourierAvailabilityStatusChannel')},
					}
				)
			}
			if (!messagesConnection && currentUser?.role === 'admin') {
				messagesConnection = cable.subscriptions.create(
					{channel: 'AdminChatNewMessageNotifyingChannel' },
					{
						connected: () => {console.log('connect to AdminChatNewMessageNotifyingChannel')},
						received: data => {setNotificationArray(p => ([...p, {...data, nID: uuidv4(), type: 'message'}]))},
						disconnected: () => {console.log('disconnected to AdminChatNewMessageNotifyingChannel')},
					}
				)
			}
		}
	}, []);

	const removeNotificationHandler = (id) => {
		const clearArr = notificationArray.filter(n => n.nID !== id);
		setNotificationArray(clearArr);
	}

	return localStorage.getItem(TOKEN) && (
		<div className="NotificationRoot">
			{notificationArray.map((nt) => (
				<Alert
					key={nt.nID}
					variant="info"
					dismissible
					onClose={() => removeNotificationHandler(nt?.nID)}
				>
					{nt.type === 'order' && (
						<>
							<p className="marginBottom10">{`${nt?.message} "${orderStatusBuilder(nt?.status || '')}"`}</p>
							<Button
								style={{padding: 0}}
								variant="link"
								onClick={() => {
									window.location.pathname = currentUser?.role === 'admin' ? `/admin/orders/${nt?.order_id}` : `merchant/${nt?.marketplace_id}/orders/${nt?.order_id}`;
								}}
							>
								Перейти к заказу
							</Button>
						</>
					)}

					{nt.type === 'courier' && (
						<>
							<p className="marginBottom10">{`${nt?.message}`}</p>
							<Button
								style={{padding: 0}}
								variant="link"
								onClick={() => {
									window.location.pathname = `/admin/couriers/${nt?.courier_id}`;
								}}
							>
								Перейти к курьеру
							</Button>
						</>
					)}

					{nt.type === 'message' && (<p className="marginBottom10">{`${nt?.message}`}</p>)}
				</Alert>
			))}
		</div>
	);
};

export default Notification;